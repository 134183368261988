import { SVGProps } from 'react'

function OrganoGovProductIcon({
  width = 279,
  height = 279,
  fill = 'none',
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 279 279" fill={fill}>
      <rect width="279" height="279" rx="40" fill="#4070F4" />
      <circle cx="194.492" cy="57.4923" r="27.4923" fill="white" />
      <circle
        cx="194.492"
        cy="57.4923"
        r="23.7584"
        stroke="white"
        strokeOpacity="0.5"
        strokeWidth="7.46785"
      />
      <circle cx="63.8302" cy="135.83" r="20.8302" fill="white" />
      <circle
        cx="63.8302"
        cy="135.83"
        r="18.0011"
        stroke="white"
        strokeOpacity="0.5"
        strokeWidth="5.6582"
      />
      <path
        d="M177.882 101.724L186.73 77.8181"
        stroke="white"
        strokeWidth="12.2194"
      />
      <circle
        cx="195.07"
        cy="209.332"
        r="22.4923"
        transform="rotate(127.658 195.07 209.332)"
        fill="#9FB8FA"
      />
      <path
        d="M174.409 167.821L185.651 190.699"
        stroke="#9FB8FA"
        strokeWidth="12.2194"
      />
      <path
        d="M114.21 134.984L83 134.984"
        stroke="white"
        strokeWidth="12.2194"
      />
      <circle cx="156.439" cy="135.966" r="44.9846" fill="white" />
      <circle
        cx="156.439"
        cy="135.966"
        r="38.8749"
        stroke="white"
        strokeOpacity="0.5"
        strokeWidth="12.2194"
      />
    </svg>
  )
}
export default OrganoGovProductIcon
