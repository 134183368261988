import { Input } from '@components/atoms'
import {
  AutocompleteRenderOptionState,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { MultiSelectChipOption } from '../InputMultiSelectChip'

type Props = {
  props: React.HTMLAttributes<HTMLLIElement>
  option: MultiSelectChipOption
  state: AutocompleteRenderOptionState
  groupNewOptionLabel?: string
}

export function RenderOption({
  props,
  option,
  state,
  groupNewOptionLabel,
}: Props): JSX.Element {
  return (
    <MenuItem {...props}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {option.group !== groupNewOptionLabel && (
          <Input type="checkbox" checked={state.selected} />
        )}
        <Typography variant="$font-body-base" color="$color-text-primary">
          <Typography
            variant={
              option.group === groupNewOptionLabel
                ? '$font-body-4'
                : '$font-body-base'
            }
            color="$color-text-primary"
          >
            {option.label}
          </Typography>
          {option.group === groupNewOptionLabel && (
            <Typography variant="$font-body-3" color="$color-text-secundary">
              &nbsp;(Aperte ENTER para criar)
            </Typography>
          )}
        </Typography>
      </Stack>
    </MenuItem>
  )
}
