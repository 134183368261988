import produce from 'immer'
import { useCallback, useMemo, useState } from 'react'
import { BucketsEnum } from '@utils/buckets/type'
import { Chip, Stack, Typography } from '@mui/material'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import { BacenEntity } from '@services/nomos_api/entities/bacen'
import { useSearchResultsUrl } from '@hooks/useSearchResultsUrl'
import {
  formatDate,
  formatterHighlight,
} from '@utils/functions/formatter/formatter'
import { Button, SaveBucketButton, TextWrap } from '@components/atoms'
import { CardTemplate } from '@components/organisms/Cards/CardTemplate'

type Props = { bacen: BacenEntity }

export function CardBacen({ bacen: bacenState }: Props) {
  const [bacen, setBacen] = useState<BacenEntity>(bacenState)
  const { url } = useSearchResultsUrl(`/bacen/${bacen.id}`)

  const handleAddSavedBucket = useCallback(
    (savedbuckets: SavedBucketEntity[]) => {
      setBacen(
        produce(bacen, (draft) => {
          draft.savedBuckets.push(...savedbuckets)
        })
      )
    },
    [setBacen, bacen]
  )

  const handleRemoveSavedBucket = useCallback(
    (removedIds: string[]) => {
      setBacen({
        ...bacen,
        savedBuckets: bacen.savedBuckets.filter(
          (sb) => !removedIds.includes(sb.id)
        ),
      })
    },
    [setBacen, bacen]
  )

  const responsible = useMemo(() => {
    return formatterHighlight(bacen, ['responsible'], 'Não Informado')
  }, [])

  const content = useMemo(() => {
    return formatterHighlight(
      bacen,
      ['subject', 'content', 'summary'],
      'Não Informado'
    )
  }, [])

  return (
    <CardTemplate
      content={[
        <>
          <Stack direction="row" spacing={2} alignItems="center">
            <Chip
              size="small"
              color="primary"
              variant="outlined"
              sx={{ paddingX: 1 }}
              label={
                <Typography
                  variant="$font-body-3"
                  color="$color-action-text-secundary"
                >
                  {bacen.type || 'Não Informado'}
                </Typography>
              }
            />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography color="$color-text-primary" variant="$font-body-1">
              {bacen.title || 'Não Informado'}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextWrap lines={2}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secundary"
              >
                {content}
              </Typography>
            </TextWrap>
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            alignItems={{ md: 'center' }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography color="$color-text-secundary" variant="$font-body-2">
                Data de publicação:
              </Typography>
              <Typography color="$color-text-primary" variant="$font-body-2">
                {formatDate(bacen.date) || 'Não Informado'}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography color="$color-text-secundary" variant="$font-body-2">
                Responsavel:
              </Typography>
              <Typography color="$color-text-primary" variant="$font-body-2">
                {responsible}
              </Typography>
            </Stack>
          </Stack>
        </>,
      ]}
      actions={[
        <SaveBucketButton
          id={bacen?.id}
          bucket={BucketsEnum.bacen}
          isSaved={bacen?.savedBuckets?.length > 0}
          openDataResource={bacen?.openDataResource}
          onCreatedSavedBucket={handleAddSavedBucket}
          onRemovedSavedBucket={handleRemoveSavedBucket}
        />,
        <Button
          text={<Typography variant="$font-body-2">Detalhes</Typography>}
          size="medium"
          icon="arrow"
          sx={{ height: '34px', m: 0 }}
          href={url}
          target="_blank"
        />,
      ]}
    />
  )
}
