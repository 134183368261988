import { InputRadio } from '@components/atoms'
import { PropositionPositioningEnum } from '@enums/PropositionPositioningEnum'
import { Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { SaveInMonitorContext } from '../tab-save-in-monitor/context'

export function MonitorFormPropositionPositioning(): JSX.Element {
  const formSavedBucket = useContextSelector(
    SaveInMonitorContext,
    (s) => s.formSavedBucket
  )
  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="$font-title-5" color="$color-text-secundary">
            Posicionamento
          </Typography>
        </Stack>
        <Typography variant="$font-body-base" color="$color-text-secundary">
          Se posicione contra, neutro ou a favor a proposição monitorada.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <InputRadio
          name="positioning"
          options={PropositionPositioningEnum}
          onChange={(value) => {
            formSavedBucket.setFieldValue('positioning', value)
          }}
        />
      </Stack>
    </Stack>
  )
}
