import { useState } from 'react'
import { Button, Input } from '@components/atoms'
import { KeyIcon, MonitorIcon } from '@components/icons'
import { useContextSelector } from 'use-context-selector'
import { Monitor } from '@services/nomos_api/entities/user'
import { UserUpsertContext } from '@containers/UserUpsert/context'
import { Box, CircularProgress, Radio, Typography } from '@mui/material'
import { useGetAlltMonitors } from '@services/nomos_api/resources/monitor/list'

import {
  CardHeader,
  Container,
  Cards,
  ContentCard,
  Actions,
  Option,
  OptionLabel,
  Panels,
  Panel,
  PanelLabel,
  Options,
} from './styles'
import { MonitorPermissionEnum } from '@enums/MonitorPermissionEnum'
import _ from 'lodash'

export default function Permissions() {
  const user = useContextSelector(UserUpsertContext, (s) => s.user)
  const setPage = useContextSelector(UserUpsertContext, (s) => s.setPage)
  const isLoading = useContextSelector(UserUpsertContext, (s) => s.isLoading)
  const isEditting = useContextSelector(UserUpsertContext, (s) => s.isEditting)

  const handleSubmit = useContextSelector(
    UserUpsertContext,
    (s) => s.handleSubmit
  )

  const [monitors, setMonitors] = useState<Monitor[]>(user?.monitors || [])

  const { data: availableMonitors, isFetching } = useGetAlltMonitors({
    all: true,
  })

  const firstMonitor = monitors?.[0]

  const [currentMonitor, setCurrentMonitor] = useState<Monitor>(firstMonitor)

  const findSelectedMonitor = (id: string) => {
    return monitors.find(({ monitorId }: Monitor) => id === monitorId)
  }

  const handleClickPanel = (id: string, role?: string) => {
    const monitor = findSelectedMonitor(id)
    setCurrentMonitor({
      role: role || monitor?.role || 'VIEWER',
      monitorId: id,
    })
  }

  const handleClickRadio = (role: string) => {
    setCurrentMonitor({
      role,
      monitorId: currentMonitor?.monitorId,
    })
    if (currentMonitor?.monitorId) {
      setMonitors([
        ...monitors.filter(
          ({ monitorId }: Monitor) => currentMonitor?.monitorId !== monitorId
        ),
        {
          role,
          monitorId: currentMonitor?.monitorId,
        },
      ])
    }
  }

  const handleCheckMonitor = (id: string, active: boolean) => {
    if (active) {
      const role = 'VIEWER'
      handleClickPanel(id, role)
      setMonitors([
        ...monitors,
        {
          role,
          monitorId: id,
        },
      ])
    } else {
      handleClickPanel(id, 'N/A')
      setMonitors(monitors.filter(({ monitorId }: Monitor) => monitorId !== id))
    }
  }

  return (
    <Container>
      <Cards>
        <ContentCard type="bordored">
          <CardHeader>
            <MonitorIcon />
            <Typography variant="$font-title-5" color="$color-text-secundary">
              Selecionar Painéis
            </Typography>
            {isFetching && <CircularProgress size={15} />}
          </CardHeader>

          <Panels>
            {availableMonitors?.map((monitor) => (
              <Panel
                key={monitor.id}
                color={
                  currentMonitor?.monitorId === monitor.id
                    ? 'active'
                    : 'inactive'
                }
              >
                <Box sx={{ pt: 0.4 }}>
                  <Input
                    type="checkbox"
                    value={monitor.id}
                    checked={!!findSelectedMonitor(monitor.id)}
                    onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleCheckMonitor(monitor.id, e.target.checked)
                    }
                  />
                </Box>

                <PanelLabel onClick={() => handleClickPanel(monitor.id)}>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {monitor.name}
                  </Typography>
                  <Typography
                    variant="$font-body-3"
                    color="$color-text-secundary"
                  >
                    {_.get(
                      MonitorPermissionEnum,
                      String(
                        monitors?.find?.((i) => i.monitorId === monitor.id)
                          ?.role
                      ),
                      'Sem Acesso'
                    )}
                  </Typography>
                </PanelLabel>
              </Panel>
            ))}
          </Panels>
        </ContentCard>

        <ContentCard type="bordored">
          <CardHeader>
            <KeyIcon />
            <Typography variant="$font-title-5" color="$color-text-secundary">
              Defina a permissão de acesso
            </Typography>
          </CardHeader>

          <Options
            name="role"
            defaultValue="N/A"
            value={currentMonitor?.role}
            onChange={(e) => handleClickRadio(e.target.value)}
          >
            <Option type="bordored">
              <Radio value="VIEWER" />
              <OptionLabel>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {MonitorPermissionEnum.VIEWER}
                </Typography>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  Permite apenas visualizar proposições, eventos e pesquisas
                  salvas nos painéis
                </Typography>
              </OptionLabel>
            </Option>

            <Option type="bordored">
              <Radio value="EDITOR" />
              <OptionLabel>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {MonitorPermissionEnum.EDITOR}
                </Typography>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  Permite associar proposições, eventos e pesquisas salvas em um
                  painel
                </Typography>
              </OptionLabel>
            </Option>

            <Option type="bordored">
              <Radio value="ADMIN" />
              <OptionLabel>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {MonitorPermissionEnum.ADMIN}
                </Typography>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  Permite excluir proposições, eventos e pesquisas salvas em um
                  painnel
                </Typography>
              </OptionLabel>
            </Option>
          </Options>
        </ContentCard>
      </Cards>

      <Actions>
        <Button
          text="Anterior"
          variant="outlined"
          disabled={isLoading}
          sx={{ width: 160, alignSelf: 'flex-end', marginTop: '14px' }}
          onClick={() => setPage(1)}
        />

        <Button
          text={isEditting ? 'Salvar' : 'Enviar Convite'}
          loading={isLoading}
          sx={{ width: 160, alignSelf: 'flex-end', marginTop: '14px' }}
          onClick={() => handleSubmit(monitors)}
        />
      </Actions>
    </Container>
  )
}
