import colors from '@theme/colors'
import { FormBreadcrumbComponent } from "./.."
import { useContextSelector } from 'use-context-selector'
import SideFilterListComponent from '../SideFilterList/SideFilterList'
import { SearchResultsContext } from '@pages/private/SearchResults/context'
import EqualizerIcon from '../../../../../components/icons/Equalizer/Equalizer'

import {
  Wrapper,
  IconClose,
  FilterTitle,
  FilterContainer,
} from './SideFilterContainer.styles'
import { Stack, IconButton } from '@mui/material'

function SideFilterContainerComponent() {

  const toggleFilter = useContextSelector(
    SearchResultsContext,
    (s) => s.toggleFilter
  )

  return (
    <Wrapper>
      <FormBreadcrumbComponent />
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" pt={7} pb={5} px={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <EqualizerIcon color={colors['$color-action-text-secundary']} />
          <FilterTitle>Filtrar por:</FilterTitle>
        </Stack>
        <IconButton size="small" onClick={() => toggleFilter()}>
          <IconClose />
        </IconButton>
      </Stack>
      <FilterContainer>
        <SideFilterListComponent />
      </FilterContainer>
    </Wrapper>
  )
}

export default SideFilterContainerComponent
