import { useModal } from '@contexts/Modal'
import { Stack, Typography } from '@mui/material'
import { Button, Input } from '@components/atoms'
import { ModalStandard } from '@components/molecules'
import { useContextSelector } from 'use-context-selector'
import { ShareCheckoutModalContext } from './context'

export function ShareCheckoutModalView(): JSX.Element {
  const { closeModal } = useModal()
  const formik = useContextSelector(ShareCheckoutModalContext, (s) => s.formik)

  return (
    <ModalStandard title="Compartilhar Link de Assinatura">
      <Stack direction="column" spacing={3}>
        <Typography variant="$font-body-base" color="$color-text-secundary">
          Compartilhe o link de assinatura do Nomos com outra pessoa em sua
          organização. Isso facilita o processo e garante que as pessoas certas
          estejam envolvidas.
        </Typography>
        <Typography variant="$font-body-2" color="$color-text-primary">
          Insira o e-mail da pessoa responsável pelo pagamento. Enviaremos a ela
          um link seguro para a assinatura.
        </Typography>
        <Input
          name="email"
          type="email"
          label="E-mail do responsável"
          size="medium"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helpertext={formik.touched.email && formik.errors.email}
          disabled={formik.isSubmitting}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <Stack
          pt={3}
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            text="Cancelar"
            variant="outlined"
            onClick={() => closeModal()}
          />
          <Button
            text="Enviar Link de Assinatura"
            loading={formik.isSubmitting}
            disabled={!(formik.isValid && formik.dirty)}
            onClick={() => formik.submitForm()}
          />
        </Stack>
      </Stack>
    </ModalStandard>
  )
}
