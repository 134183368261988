import moment from 'moment-timezone'
import EmptyState from '../EmptyState'
import { TextLink } from '@components/atoms'
import { useNavigate } from 'react-router-dom'
import { ScheduleIcon } from '@components/icons'
import { MiniEventCard } from '@components/organisms/Cards'
import { CircularProgress, Typography } from '@mui/material'
import { EventEntity } from '@services/nomos_api/entities/event'
import { useGetAllSavedEvents } from '@services/nomos_api/resources/saved_event/getAll'

import { MonitorCard, CardHeader, CardTitle, CardContent } from '../styles'

import { EventsContainer } from './styles'

export default function Events() {
  const navigate = useNavigate()

  const { data: events, isFetching: isLoadingEvents } = useGetAllSavedEvents({
    fromDate: moment().startOf('day').format('YYYY-MM-DD'),
    toDate: moment().endOf('week').format('YYYY-MM-DD'),
  })

  return (
    <MonitorCard type="bordored" height={336}>
      <CardHeader>
        <CardTitle>
          <ScheduleIcon />
          <Typography variant="$font-body-1" color="$color-text-primary">
            Agenda da semana
          </Typography>

          {isLoadingEvents && <CircularProgress size={15} />}
        </CardTitle>

        <TextLink text="Ver agenda" href="/events" />
      </CardHeader>

      {!isLoadingEvents && events?.length > 0 && (
        <>
          <CardHeader>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Eventos da sua agenda que acontecerão essa semana
            </Typography>

            <Typography variant="$font-title-1" color="$color-action-link">
              {events?.length}
            </Typography>
          </CardHeader>

          <EventsContainer>
            {events?.map((event: EventEntity) => (
              <MiniEventCard
                event={event}
                key={event.id}
                onClick={() => navigate(`/events/${event.id}`)}
              />
            ))}
          </EventsContainer>
        </>
      )}

      {!isLoadingEvents && events?.length === 0 && (
        <CardContent>
          <EmptyState
            text="Os eventos que você monitora serão exibidos aqui semanalmente como lembrete para a sua agenda da semana."
            action="Pesquisar Eventos"
            onClick={() => navigate('/search-results?keyword=&buckets=events')}
          />
        </CardContent>
      )}
    </MonitorCard>
  )
}
