import React from 'react'
import { uid } from 'uid'
import { Typography } from '@mui/material'
import { useAlerts } from '@hooks/useAlerts'
import { EmptyState } from '@components/molecules'
import { Container, MessagesHeader } from './styles'
import { CardMessage } from '@components/organisms/Cards'
import { AlertEntity } from '@services/realtime_db/entities/alert'
import { Button, ContainerScroll, LoadingFloat } from '@components/atoms'

export default function NewMessages() {
  const {
    alerts,
    loading,
    removing,
    loadedAll,
    countTotal,
    handlePaginate,
    handleRemoveAll,
  } = useAlerts(['addeds', 'count_total'])

  const onScrollBottom = React.useCallback(() => {
    if (!loading && !loadedAll) {
      handlePaginate()
    }
  }, [loading, loadedAll, handlePaginate])

  React.useEffect(() => {
    handlePaginate()
  }, [])

  return (
    <Container>
      <MessagesHeader>
        <Typography variant="$font-body-base" color="$color-text-secundary">
          {countTotal} {countTotal === 0 ? 'Notificações' : 'Novas mensagens'}
        </Typography>
        {countTotal > 0 && (
          <Button
            variant="text"
            text="Marcar todas como lida"
            onClick={handleRemoveAll}
            loading={removing}
          />
        )}
      </MessagesHeader>

      {!loading && countTotal === 0 && (
        <EmptyState text="Não há notificações a serem exibidas" />
      )}

      {countTotal > 0 && (
        <ContainerScroll onScrollBottom={onScrollBottom}>
          {alerts.map((alert: AlertEntity, index: number) => (
            <CardMessage
              color="error"
              alert={alert}
              prevAlert={alerts[index - 1]}
              key={`alert-${alert.searchId}-${uid()}`}
            />
          ))}
        </ContainerScroll>
      )}

      <LoadingFloat visible={loading} label="carregando..." />
    </Container>
  )
}
