import { useParams } from 'react-router-dom'
import { CircularProgress, Typography } from '@mui/material'
import { useGetOrganMembersCount } from '@services/nomos_api/resources/organs/members'

import { Container, Content, Element, Elements } from './styles'

export default function SumMembers() {
  const { id } = useParams()
  const { data, isFetching } = useGetOrganMembersCount(id)

  return (
    <Container>
      <Content>
        <Typography variant="$font-title-4" color="$color-text-primary">
          Número de Membros
        </Typography>

        <Elements>
          <Element>
            <Typography variant="$font-body-1" color="$color-text-secundary">
              Titulares
            </Typography>

            {isFetching ? (
              <CircularProgress size={15} />
            ) : (
              <Typography variant="$font-body-1" color="$color-text-secundary">
                {data?.holders || 0}
              </Typography>
            )}
          </Element>
          <Element>
            <Typography variant="$font-body-1" color="$color-text-secundary">
              Suplentes
            </Typography>
            {isFetching ? (
              <CircularProgress size={15} />
            ) : (
              <Typography variant="$font-body-1" color="$color-text-secundary">
                {data?.substitutes || 0}
              </Typography>
            )}
          </Element>
        </Elements>
      </Content>

      {isFetching ? (
        <CircularProgress size={40} />
      ) : (
        <Typography
          variant="$font-title-display"
          color="$color-action-text-secundary"
        >
          {data?.total || 0}
        </Typography>
      )}
    </Container>
  )
}
