import { Typography } from '@mui/material'
import { UserUpsertContext } from './context'
import GeneralData from './containers/GeneralData'
import Permissions from './containers/Permissions'
import { useContextSelector } from 'use-context-selector'

import { Container, Timeline, Step, Line, Circle } from './styles'

export function UserUpsertView() {
  const page = useContextSelector(UserUpsertContext, (s) => s.page)

  return (
    <Container>
      <Timeline>
        <Step>
          <Circle color={page === 1 ? 'active' : 'inactive'}>1</Circle>
          <Typography
            variant="$font-body-base"
            color={page === 1 ? '$color-text-primary' : '$color-text-secundary'}
          >
            Dados Gerais
          </Typography>
        </Step>

        <Line />

        <Step>
          <Circle color={page === 2 ? 'active' : 'inactive'}>2</Circle>
          <Typography
            variant="$font-body-base"
            color={page === 2 ? '$color-text-primary' : '$color-text-secundary'}
          >
            Permissões
          </Typography>
        </Step>
      </Timeline>

      {page === 1 && <GeneralData />}
      {page === 2 && <Permissions />}
    </Container>
  )
}
