/* eslint-disable @typescript-eslint/no-explicit-any */
import Badge from '@mui/material/Badge'
import { MouseEvent, useState } from 'react'
import { useDrawer } from '@contexts/Drawer'
import { FormikProps, useFormik } from 'formik'
import { BucketsEnum } from '@utils/buckets/type'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useContextSelector } from 'use-context-selector'
import { BellNotificationIcon, TrashCan } from '@components/icons'
import { SavedOrganUpdatesKeys } from '@enums/SavedOrganUpdatesEnum'
import { PropositionPositioningContext } from '../propositions/context'
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { deleteSavedPropositionsApi } from '@services/nomos_api/resources/saved_proposition/delete'
import { updateSavedPropositionAPI } from '@services/nomos_api/resources/monitor/update-saved-proposition'
import { PropositionStatusNotification } from '@containers/SharedDrawers/MonitoringDrawer/containers/form-proposition-status-notification'

export type PropositionOptionsProps = {
  proposition: any
}

export default function PropositionOptions({
  proposition,
}: PropositionOptionsProps) {
  const { openDrawer, closeDrawer } = useDrawer()

  const refetch = useContextSelector(
    PropositionPositioningContext,
    (c) => c.refetch
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  // define the use state for PropositionStatusNotificationView
  const formik = useFormik<{
    muteuser?: boolean
    situations?: string[]
    alert?: {
      destinations?: string[]
      frequency?: string
    }
  }>({
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      muteuser: proposition.savedProposition?.alert?.muted !== true,
      situations: proposition?.savedProposition?.situations || [],
      alert: {
        destinations: proposition?.savedProposition?.alert?.destinations || [
          'email',
        ],
        frequency:
          proposition?.savedProposition?.alert?.frequency || 'realtime',
      },
    },
    onSubmit: (values): Promise<void> => {
      return updateSavedPropositionAPI({
        savedPropositionId: proposition.savedProposition.id,
        muteuser: values.muteuser,
        situations: values.situations,
        alert: {
          destinations: values.alert?.destinations,
          frequency: values.alert?.frequency,
        },
      }).then(() => {
        closeDrawer()
        refetch()
      })
    },
  })

  const handleDeleteProposition = async () => {
    await deleteSavedPropositionsApi([proposition.savedProposition.id])
    await refetch()
  }

  const handleNotifications = async (proposition: any) => {
    setAnchorEl(null)
    openDrawer(
      <PropositionStatusNotification
        monitors={[]}
        handleSubmit={() =>
          new Promise((resolve) => {
            formik.submitForm().finally(() => resolve())
          })
        }
        formSavedBucket={
          formik as unknown as FormikProps<{
            [x: string]: string | string[]
            monitors: string[]
            bucket: BucketsEnum
            trackedUpdates: SavedOrganUpdatesKeys[]
            trackedPropositionTypes: string[]
          }>
        }
        enabledNotification={
          proposition.savedProposition?.alert?.muted !== true
        }
        setEnabledNotification={(enabledNotification: boolean) => {
          formik.setFieldValue('muteuser', !enabledNotification)
        }}
        alertChannel={
          proposition?.savedProposition?.alert?.destinations || ['email']
        }
        setAlertChannel={(destinations?: string[]) => {
          formik.setFieldValue('alert.destinations', destinations || [])
        }}
        alertFrequency={
          proposition?.savedProposition?.alert?.frequency || 'realtime'
        }
        setAlertFrequency={(frequency: string) => {
          formik.setFieldValue('alert.frequency', frequency)
        }}
        propositionStatus={proposition?.savedProposition?.situations || []}
        setPropositionStatus={(situations: string[]) => {
          formik.setFieldValue('situations', situations)
        }}
        house={proposition.openDataResource}
        isSavingBucket={false}
        disableDirty
      />,
      { lockscroll: true }
    )
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={id}
        anchorEl={anchorEl}
        sx={{ mt: '8px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(open)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleDeleteProposition}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Stack
              width={24}
              height={24}
              alignItems="center"
              justifyContent="center"
            >
              <TrashCan />
            </Stack>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Remover do Painel
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={() => handleNotifications(proposition)}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Badge color="error" variant="dot" overlap="circular">
              <Stack
                width={24}
                height={24}
                alignItems="center"
                justifyContent="center"
              >
                <BellNotificationIcon />
              </Stack>
            </Badge>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Configurar Notificação
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  )
}
