import { useState } from 'react'
import { Typography, Stack } from '@mui/material'
import { NoDataIcon } from '@components/icons'
import { Input, Avatar } from '@components/atoms'
import { PropositionVotingEntity } from '@services/nomos_api/entities/proposition'

import {
  Checkbox,
  ContainerColumn,
  ContainerEnd,
  ContainerRow,
  FilterableCard,
  Stakeholder,
  Header,
  List,
  ContainerGap,
  NoData,
} from './Stakeholders.styles'
import { VotePositioningEnum } from '@enums/VotePositioningEnum'

export type GuidelinesProps = {
  votings: PropositionVotingEntity
}

export default function Stakeholders({ votings }: GuidelinesProps) {
  const [textFilter, setTextFiler] = useState('')
  const [checkFilter, setCheckFilter] = useState('')

  const votes = (votings?.votes || [])
    .filter((vote) =>
      textFilter
        ? vote.parliamentarian.name
            .toLowerCase()
            .indexOf(textFilter.toLowerCase()) !== -1
        : true
    )
    .filter((vote) =>
      checkFilter ? vote.positioningType === checkFilter : true
    )

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckFilter(e.target.value)
    } else {
      setCheckFilter('')
    }
  }

  return (
    <FilterableCard>
      <Header sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={2}>
        <ContainerColumn>
          <Typography variant="$font-title-3" color="$color-text-primary">
            Stakeholders
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Veja abaixo o posicionamento <br />
            por Stakeholder
          </Typography>
        </ContainerColumn>

        <Stack gap={2} direction={{ xs: 'row', lg: 'column' }} flexWrap="wrap">
          <Input
            size="small"
            type="search"
            label="Stakeholder"
            sx={{ widht: { xs: '100%', lg: 'auto' } }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTextFiler(e.target.value)
            }}
          />
          <ContainerEnd>
            <Checkbox>
              <Input
                type="checkbox"
                value="infavor"
                checked={checkFilter === 'infavor'}
                onChange={handleCheckbox}
              />
              {VotePositioningEnum.infavor}
            </Checkbox>
            <Checkbox>
              <Input
                type="checkbox"
                value="against"
                checked={checkFilter === 'against'}
                onChange={handleCheckbox}
              />
              {VotePositioningEnum.against}
            </Checkbox>
            <Checkbox>
              <Input
                type="checkbox"
                value="other"
                checked={checkFilter === 'other'}
                onChange={handleCheckbox}
              />
              {VotePositioningEnum.other}
            </Checkbox>
          </ContainerEnd>
        </Stack>
      </Header>

      {votes.length === 0 && (
        <NoData>
          <NoDataIcon
            height={40}
            text="Não há posicionamento de Stakeholders a serem exibidos"
          />
        </NoData>
      )}

      <List>
        {votes?.map((vote) => (
          <Stakeholder key={vote.parliamentarian.name}>
            <Avatar
              alt={vote.parliamentarian?.name}
              src={vote.parliamentarian?.photoUrl}
              width={56}
              height={56}
            />

            <ContainerColumn>
              <Typography variant="$font-title-5" color="$color-text-primary">
                {vote.parliamentarian?.name}
              </Typography>

              <ContainerGap>
                <ContainerRow>
                  <Typography
                    variant="$font-body-2"
                    color="$color-text-secundary"
                  >
                    Partido:
                  </Typography>

                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {vote.parliamentarian?.acronymParty}
                  </Typography>
                </ContainerRow>

                <ContainerRow>
                  <Typography
                    variant="$font-body-2"
                    color="$color-text-secundary"
                  >
                    UF:
                  </Typography>

                  <Typography
                    variant="$font-body-base"
                    color="$color-text-primary"
                  >
                    {vote.parliamentarian?.uf}
                  </Typography>
                </ContainerRow>

                <ContainerRow>
                  <Typography
                    variant="$font-body-2"
                    color="$color-text-secundary"
                  >
                    Voto:
                  </Typography>

                  <Typography
                    variant="$font-body-base"
                    color={
                      vote.positioningType === 'infavor'
                        ? '$color-semantic-success'
                        : '$color-semantic-error'
                    }
                  >
                    {VotePositioningEnum[vote.positioningType]}
                    {vote.positioningType === 'other' &&
                      ` / ${vote.positioning}`}
                  </Typography>
                </ContainerRow>
              </ContainerGap>
            </ContainerColumn>
          </Stakeholder>
        ))}
      </List>
    </FilterableCard>
  )
}
