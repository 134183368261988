/* eslint-disable @typescript-eslint/no-explicit-any */
import colors from '@theme/colors'
import { Stack, Tooltip, Typography } from '@mui/material'
import { TypographyWrap } from '@components/atoms'
import { Description } from '@components/molecules'
import LinkTo from '@components/atoms/LinkTo/LinkTo'
import { EventScheduleEntity } from '@entities/EventScheduleEntity'
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material'
import { Indicator } from '@components/molecules/Status/Status.styles'
import { AccordionContentItem, ChipMonitors, PropositionStatus } from './styles'

export type PropositionItemProps = {
  item: EventScheduleEntity
  showStatus: boolean
}

export function PropositionItem({ item, showStatus }: PropositionItemProps) {
  return (
    <AccordionContentItem>
      <Stack
        spacing={3}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack spacing={2} direction="row" alignItems="center" flexWrap="wrap">
          {item?.propositionId ? (
            <LinkTo
              target="_blank"
              to={`/propositions/${item?.propositionId}`}
              color="$text-color-action-link"
              title={item.title}
            />
          ) : (
            <TypographyWrap
              variant="$font-body-base"
              color="$color-text-primary"
            >
              {item.title}
            </TypographyWrap>
          )}

          {!!item?.propositionType && (
            <Tooltip title={item.propositionType} placement="top">
              <TypographyWrap
                variant="$font-body-base"
                color="$color-text-primary"
              >
                {item.propositionType}
              </TypographyWrap>
            </Tooltip>
          )}

          <Description
            label="Autor:"
            value={item.authors?.[0]?.name}
            evidence={item.authors?.[0]?.acronymParty}
          />
          <Description
            label="Relator:"
            value={item.rapporteurs?.[0]?.name}
            evidence={item.rapporteurs?.[0]?.acronymParty}
          />
          <Description
            label="Apensado a:"
            value={item.relatedTitle}
            link={
              item.relatedPropositionId &&
              `/propositions/${item.relatedPropositionId}`
            }
          />
          {item.monitors.length > 0 && (
            <Tooltip
              title={item.monitors.map((i) => i.name).join(', ')}
              placement="top"
            >
              <ChipMonitors>
                <Indicator color={colors['$color-semantic-success']} />
                <Typography variant="$font-body-5" color="$color-text-primary">
                  proposição monitorada em:{' '}
                  {item.monitors
                    .slice(0, 2)
                    .map((i) => i.name)
                    .join(', ')}
                  {item.monitors.length > 2 && `+ ${item.monitors.length - 2}`}
                </Typography>
              </ChipMonitors>
            </Tooltip>
          )}
        </Stack>

        {!!showStatus && (
          <PropositionStatus isApproved={item?.approved || false}>
            {item?.approved ? (
              <>
                <CheckCircleOutline />
                <span>Aprovada</span>
              </>
            ) : (
              <>
                <HighlightOff />
                <span>Rejeitada</span>
              </>
            )}
          </PropositionStatus>
        )}
      </Stack>

      <Typography variant="$font-body-base" color="$color-text-secundary">
        {item?.summary}
      </Typography>

      {!!showStatus && (
        <LinkTo
          to={`/propositions/${item?.propositionId || 'unknown'}?tab=votings`}
          title="Detalhes da votação"
        />
      )}
    </AccordionContentItem>
  )
}
