import { MonitoringContext } from '../../context'
import SaveInMonitor from '../tab-save-in-monitor'
import { useContextSelector } from 'use-context-selector'
import RemoveFromMonitor from '../tab-remove-from-monitor'

export function MonitoringTabsSelector(): JSX.Element {
  const selectedTab = useContextSelector(
    MonitoringContext,
    (s) => s.selectedTab
  )
  if (selectedTab === 'monitor') return <SaveInMonitor />
  if (selectedTab === 'unmonitor') return <RemoveFromMonitor />
  return <div>unselected panel</div>
}
