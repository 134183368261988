import { Typography } from '@mui/material'
import { ModalContainer, Header, Body, Footer, Content } from './styles'
import { Button, YoutubeEmbed } from '@components/atoms'
import { useModal } from '@contexts/Modal'

export default function OnboardModalView() {
  const { cleanStack } = useModal()

  return (
    <ModalContainer>
      <Body>
        <Header>
          <Typography variant="$font-title-3" color="$color-text-primary">
            Parabéns! Você possui acesso total ao Nomos a partir de agora
          </Typography>
          <Typography variant="$font-body-1" color="$color-text-secundary">
            Antes de seguir explorando a solução e todas as possibilidades que
            permite, sugerimos que você assista a este rápido tutorial com uma
            visão geral sobre o produto e como ele funciona
          </Typography>
          <br />
          <Typography variant="$font-title-5" color="$color-text-secundary">
            São só 5 minutos! Mas, não se preocupe você pode pular esta etapa se
            assim desejar.
          </Typography>
        </Header>
        <Content>
          <YoutubeEmbed
            videoId="6_sCy7lPcCw"
            width="575"
            height="323"
            title="Nomos onboard video"
          />
        </Content>
        <Typography variant="$font-body-1" color="$color-text-secundary">
          Você pode assistir esse e outros tutoriais em nosso{' '}
          <a
            href="https://www.nomosapp.com.br/faq"
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </a>{' '}
          quando quiser.
        </Typography>
      </Body>
      <Footer>
        <Button
          type="button"
          size="medium"
          text="Pular"
          loading={false}
          disabled={false}
          onClick={() => cleanStack()}
          sx={{ width: 104, alignSelf: 'flex-end' }}
        />
      </Footer>
    </ModalContainer>
  )
}
