import { Button, Input } from '@components/atoms'
import { Box, MenuItem, Stack, Typography } from '@mui/material'

type Props = {
  group: string
  children: React.ReactNode
  fnRemoveAll?: () => void
  fnSelectAll?: () => void
}

export function RenderGroup({
  group,
  children,
  fnRemoveAll,
  fnSelectAll,
}: Props): JSX.Element {
  return (
    <Stack direction="column">
      <Box
        px={2}
        py={0.5}
        gap={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="$font-body-base" color="$color-text-secundary">
          {group}
        </Typography>
        {group === 'Selecionados' && !!fnRemoveAll && (
          <Button
            text="limpar"
            variant="text"
            size="tiny"
            sx={{ px: 1 }}
            onClick={fnRemoveAll}
          />
        )}
      </Box>
      {group === 'Outros' && fnSelectAll && (
        <MenuItem onClick={fnSelectAll}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Input type="checkbox" />
            <Typography variant="$font-body-base" color="$color-text-primary">
              Selecionar todos
            </Typography>
          </Stack>
        </MenuItem>
      )}
      {children}
    </Stack>
  )
}
