import { MenuItem, TextField, Typography } from '@mui/material'
import { Wrapper } from './FormCountResults.styles'
import { useContextSelector } from 'use-context-selector'
import { pageview } from '@utils/functions/pageview/pageview'
import { SearchResultsContext } from '@pages/private/SearchResults/context'
import { decodeKeywords } from '@utils/functions/keyword/serialize'
import { KeywordRender } from '@components/atoms'

function FormCountResultsComponent() {
  const keyword = useContextSelector(SearchResultsContext, (s) => s.keyword)
  const bucket = useContextSelector(SearchResultsContext, (s) => s.bucket)
  const search = useContextSelector(SearchResultsContext, (s) => s.search)
  const keywords = decodeKeywords(keyword)

  const handleUpdateSearch = useContextSelector(
    SearchResultsContext,
    (s) => s.handleUpdateSearch
  )

  const selectedBucket = useContextSelector(
    SearchResultsContext,
    (s) => s.selectedBucket
  )

  const pagination = useContextSelector(
    SearchResultsContext,
    (s) => s.pagination
  )

  const keyPrefix = `buckets.${selectedBucket}`
  
  return (
    <Wrapper>
      <Typography variant="$font-body-base" color="$color-text-primary">
        <Typography variant="$font-body-base" color="$color-text-primary" sx={{ float: "left"}}>
          Exibindo {pageview(pagination || {})} resultados{' '}
          {keyword && `para`} &nbsp;
        </Typography>
        <KeywordRender value={{ keywords }} tiny inline envolve={[`"`,`"`]}/>
      </Typography>
      <TextField
        select
        size="tiny"
        label="Ordenar por"
        value={_.get(search, `${keyPrefix}.sort`, '')}
        onChange={(e) =>
          handleUpdateSearch({
            [`${keyPrefix}.sort`]: e.target.value,
          })
        }
        sx={{ width: {xs: '100%',md: 205}}}
      >
        {(bucket?.sorts || []).map((option) => (
          <MenuItem
            key={option.attr}
            value={option.attr}
            sx={{ fontSize: '14px', color: '#556282' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Wrapper>
  )
}

export default FormCountResultsComponent
