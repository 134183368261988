import { BlockedFeatureContext } from '../context'
import LockIcon from '@components/icons/Lock/Lock'
import { useContextSelector } from 'use-context-selector'
import {
  NomosProductCodeEnum,
  NomosProductLabel,
} from '@enums/NomosProductEnum'
import { Stack, Alert, Divider, Typography } from '@mui/material'
import { WhatsAppPaymentLink } from '@containers/WhatsAppPaymentLink'

export function BlockedFeatureStateMissingSubscription(): JSX.Element {
  const requiredRole = useContextSelector(
    BlockedFeatureContext,
    (s) => s.requiredRole
  )
  return (
    <Stack direction="column" spacing={3}>
      <Alert variant="standard" color="info" icon={false}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LockIcon />
          <Typography variant="$font-body-1" color="$color-text-primary">
            Recurso exclusivo para assinantes do plano{' '}
            <Typography variant="$font-title-5" color="$color-text-primary">
              {
                NomosProductLabel[
                  requiredRole || NomosProductCodeEnum.MONITOR_PRO
                ]
              }
            </Typography>
          </Typography>
        </Stack>
      </Alert>
      <Divider />
      <Stack direction="column">
        <Typography variant="$font-title-4" color="$color-text-secundary">
          Seu plano não permite acesso a este recurso.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <WhatsAppPaymentLink
          text="Falar com especialista"
          title="Falar com especialista"
          size="large"
        />
      </Stack>
    </Stack>
  )
}
