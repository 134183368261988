import { Stack, Tooltip, Typography } from '@mui/material'
import { TypographyWrap } from '@components/atoms/index'
import LinkTo from '@components/atoms/LinkTo/LinkTo'

const defaultProps = {
  evidence: '',
}

type DescriptionProps = {
  label: string
  value?: string
  evidence?: string
  link?: string
}

function Description({ label, value, evidence, link }: DescriptionProps) {
  if (!value) return null
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="$font-body-base" color="$color-text-secundary">
        {label}
      </Typography>
      <Tooltip
        title={`${value}${!evidence ? '' : ` (${evidence})`}`}
        placement="top"
      >
        {link ? (
          <LinkTo
            to={link}
            target="_blank"
            color="$text-color-action-link"
            title={`${value}${evidence && ` (${evidence})`}`}
          />
        ) : (
          <TypographyWrap variant="$font-body-base" color="$color-text-primary">
            {value} {evidence && `(${evidence})`}
          </TypographyWrap>
        )}
      </Tooltip>
    </Stack>
  )
}

Description.defaultProps = defaultProps

export default Description
