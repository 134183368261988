import { X } from '@phosphor-icons/react'
import { useModal } from '@contexts/Modal'
import { Button, InputRadio } from '@components/atoms'
import { ModalBody, ModalFooter, ModalHeader } from './styles'
import { Divider, IconButton, Stack, Typography } from '@mui/material'
import { ReportCategorySetting } from './components/ReportCategorySetting'
import { useContextSelector } from 'use-context-selector'
import { ModalMonitorReportContext } from './context'
import { ReportFormatEnum } from '@enums/ReportFormatEnum'
import { LoadingButton } from '@mui/lab'

export function ModalMonitorReportView(): JSX.Element {
  const { closeModal } = useModal()
  const form = useContextSelector(ModalMonitorReportContext, (s) => s.form)
  const isLoading = useContextSelector(
    ModalMonitorReportContext,
    (s) => s.isLoading
  )

  return (
    <>
      <ModalHeader>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-title-3" color="$color-text-primary">
            Relatório
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Selecione uma ou mais categoria de dados para gerar o relatório.
          </Typography>
        </Stack>
        <IconButton size="small" onClick={closeModal}>
          <X size={16} />
        </IconButton>
      </ModalHeader>
      <ModalBody>
        <Stack direction="column" spacing={4}>
          <Stack
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="$font-title-5" color="$color-text-primary">
              Categoria de Dados
            </Typography>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="$font-title-5" color="$color-text-primary">
                Formato
              </Typography>
              <InputRadio
                outlined
                size="12"
                spacing={1}
                name="format"
                options={ReportFormatEnum}
                value={form.values.format || 'pdf'}
                onChange={(value) => form.setFieldValue('format', value)}
              />
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1}>
            <ReportCategorySetting
              name="events"
              title="Eventos e Pauta Semanal"
              description="Exibe os eventos monitorados e projetos que estarão em pauta no período selecionado"
              enabledPeriod
              onChange={(value) =>
                form.setFieldValue('categories.events', value)
              }
            />
            <Divider />
            <ReportCategorySetting
              name="propositions"
              title="Proposição"
              description="Exibe os projetos monitorados no painel que tramitaram no período selecionado"
              enabledPeriod
              onChange={(value) =>
                form.setFieldValue('categories.propositions', value)
              }
            />
            <Divider />
            <ReportCategorySetting
              name="search_data_found"
              title="Dados encontrados através de pesquisa salva"
              description="Exibe os dados encontrados no período selecionado"
              enabledPeriod
              onChange={(value) =>
                form.setFieldValue('categories.search_data_found', value)
              }
            />
            <Divider />
            <ReportCategorySetting
              name="stakeholders"
              title="Stakeholders"
              description="Lista todos os stakeholders monitorados no painel"
              onChange={(value) =>
                form.setFieldValue('categories.stakeholders', value)
              }
            />
            <Divider />
            <ReportCategorySetting
              name="organs"
              title="Órgãos"
              description="Lista todos os órgãos monitorados no painel"
              onChange={(value) =>
                form.setFieldValue('categories.organs', value)
              }
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <div>&nbsp;</div>
        <Stack direction="row" spacing={2}>
          <Button text="Sair" variant="outlined" onClick={closeModal} />
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={() => form.handleSubmit()}
            disabled={!(form.isValid && form.dirty)}
          >
            Gerar Relatório
          </LoadingButton>
        </Stack>
      </ModalFooter>
    </>
  )
}
