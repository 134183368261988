import { uid } from 'uid'
import { useCallback } from 'react'
import { Container } from './styles'
import { Typography } from '@mui/material'
import { EmptyState } from '@components/molecules'
import { CardMessage } from '@components/organisms/Cards'
import { AlertEntity } from '@services/nomos_api/entities/alert'
import { ContainerScroll, LoadingFloat } from '@components/atoms'
import { useInfinityAlerts } from '@services/nomos_api/resources/alerts/getAll'

export default function AllMessages() {
  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfinityAlerts()

  const onScrollBottom = useCallback(() => {
    if (!isFetching && !isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [isFetching, isFetchingNextPage, hasNextPage, fetchNextPage])

  const alerts =
    data?.pages.reduce(
      (previous, current) => [...previous, ...current.results],
      [] as AlertEntity[]
    ) || []

  return (
    <Container>
      {data && (
        <Typography variant="$font-body-base" color="$color-text-secundary">
          {data.pages[0].pagination.total || 0} Notificações
        </Typography>
      )}

      {!isFetching && alerts.length === 0 && (
        <EmptyState text="Não há notificações a serem exibidas" />
      )}

      {alerts.length > 0 && (
        <ContainerScroll onScrollBottom={onScrollBottom}>
          {alerts.map((alert: AlertEntity, index: number) => (
            <CardMessage
              alert={alert}
              prevAlert={alerts[index - 1]}
              key={`notification-item-${alert.title}-${uid()}`}
            />
          ))}
        </ContainerScroll>
      )}

      <LoadingFloat visible={isFetching} label="carregando..." />
    </Container>
  )
}
