/* eslint-disable no-restricted-syntax */
/* eslint-disable no-inline-styles/no-inline-styles */
import { useMemo } from 'react'
import colors from '@theme/colors'
import { Stack, Typography } from '@mui/material'
import { ModalStandard } from '@components/molecules'
import { Button, ContainerScroll } from '@components/atoms'
import { EventEntity } from '@services/nomos_api/entities/event'
import { useModal } from '@contexts/Modal'

type Props = {
  event: Partial<EventEntity>
}

function buildHilightContent(
  hilightwords: string[],
  content: string
): Array<string | JSX.Element> {
  return content.split(' ').reduce((agg: Array<string | JSX.Element>, word) => {
    if (hilightwords.includes(word.toLowerCase())) {
      agg.push(
        ' ',
        <b
          style={{
            borderRadius: 3,
            paddingLeft: 3,
            paddingRight: 3,
            color: colors['$color-text-primary'],
            backgroundColor: colors['$color-extended-yellow2'],
          }}
        >
          {word}
        </b>,
        ' '
      )
    } else {
      agg.push(' ', word, ' ')
    }
    return agg
  }, [])
}

export function EventScheduleMatch({ event }: Props): JSX.Element {
  const { closeModal } = useModal()

  const schedules = useMemo(() => {
    // find hilight words
    const hilightwords = (event.highlights || [])?.reduce((words, item) => {
      if (item.path.startsWith('schedule')) {
        words.push(
          ...item.texts
            .filter((text) => text.type === 'hit')
            .map((text) => text.value.toLowerCase())
        )
      }
      return words
    }, [] as string[])

    // filter schedules that matches with higlith words
    const filteredSchedules = (event.schedule || []).filter((schedule) => {
      for (const word of hilightwords) {
        if (
          schedule.title.toLowerCase().includes(word) ||
          schedule.summary.toLowerCase().includes(word)
        ) {
          return true
        }
      }
      return false
    })

    // iterate filtered schedules and transform it to add hilights
    const transformedSchedules = filteredSchedules.map((schedule) => {
      return {
        propositionId: schedule.propositionId,
        title: buildHilightContent(hilightwords, schedule.title),
        summary: buildHilightContent(hilightwords, schedule.summary),
      }
    })

    // return transformed schedules
    return transformedSchedules
  }, [event])

  return (
    <ModalStandard title="Palavra-chave encontrada nos itens da pauta">
      <Stack
        direction="column"
        spacing={1}
        sx={{ flex: 1, overflow: 'hidden', maxHeight: 500 }}
      >
        <ContainerScroll spacing={0} disableScrollIndicator>
          {schedules.map((schedule, i) => (
            <Stack
              key={`schedule-${schedule.propositionId}`}
              spacing={1}
              sx={{
                borderWidth: 0,
                paddingBottom: 1,
                paddingTop: 1,
                borderTopWidth: i === 0 ? 0 : 1,
                borderStyle: 'solid',
                borderTopColor: colors['$color-border-primary'],
              }}
            >
              <Typography variant="$font-body-1" color="$color-text-primary">
                {schedule?.title || ''}
              </Typography>
              <Typography
                fontWeight="400"
                variant="$font-body-2"
                color="$color-text-secundary"
              >
                <Typography
                  fontWeight="500"
                  variant="$font-body-2"
                  color="$color-text-primary"
                >
                  Ementa:&nbsp;
                </Typography>
                {schedule?.summary || ''}
              </Typography>
            </Stack>
          ))}
        </ContainerScroll>
        <Stack alignItems="flex-end" justifyContent="flex-end" pt={3}>
          <Button variant="outlined" text="Fechar" onClick={closeModal} />
        </Stack>
      </Stack>
    </ModalStandard>
  )
}
