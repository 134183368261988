import LockIcon from '@components/icons/Lock/Lock'
import { Stack, Alert, Divider, Typography } from '@mui/material'
import { WhatsAppPaymentLink } from '@containers/WhatsAppPaymentLink'

export function BlockedFeatureStateUserUnauthenticated(): JSX.Element {
  return (
    <Stack direction="column" spacing={3}>
      <Alert variant="standard" color="info" icon={false}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LockIcon />
          <Typography variant="$font-body-1" color="$color-text-primary">
            Você precisa estar autenticado
          </Typography>
        </Stack>
      </Alert>
      <Divider />
      <Stack direction="column">
        <Typography variant="$font-title-4" color="$color-text-secundary">
          Tem interesse em acessar recursos exclusivos do nomos monitoramento?
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <WhatsAppPaymentLink
          text="Teste Gratuitamente"
          title="Teste Gratuitamente"
          size="large"
        />
      </Stack>
    </Stack>
  )
}
