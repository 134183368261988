/* eslint-disable no-restricted-syntax */
import _ from 'lodash'
import produce from 'immer'
import { theme } from '@theme/index'
import { useCallback, useMemo, useState } from 'react'
import { CardTemplate } from '../CardTemplate'
import { BucketsEnum } from '@utils/buckets/type'
import { Chip, Stack, Typography } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import { useSearchResultsUrl } from '@hooks/useSearchResultsUrl'
import {
  formatDate,
  formatterHighlight,
} from '@utils/functions/formatter/formatter'
import { DouEntity } from '@services/nomos_api/resources/dou/getById'
import { Button, TextWrap, SaveBucketButton } from '@components/atoms'

type Props = { dou: DouEntity }
export function CardDou({ dou: douState }: Props) {
  const [dou, setDou] = useState<DouEntity>(douState)
  const { url } = useSearchResultsUrl(`/dou/${dou.id}`)

  const handleAddSavedBucket = useCallback(
    (savedbuckets: SavedBucketEntity[]) => {
      setDou(
        produce(dou, (draft) => {
          draft.savedBuckets.push(...savedbuckets)
        })
      )
    },
    [setDou, dou]
  )

  const handleRemoveSavedBucket = useCallback(
    (removedIds: string[]) => {
      setDou({
        ...dou,
        savedBuckets: dou.savedBuckets.filter(
          (sb) => !removedIds.includes(sb.id)
        ),
      })
    },
    [setDou, dou]
  )

  const hierarchy = dou.hierarchy.split(',')
  const labelsSection = {
    'SEÇÃO 1': 'Atos do normativo',
    'SEÇÃO 2': 'Atos do pessoal',
    'SEÇÃO 3': 'Atos do executivo',
  }

  const labelChip = `${dou.section} - ${_.get(
    labelsSection,
    dou.section.substring(0, 7).toUpperCase()
  )}`

  const title = useMemo(() => {
    return formatterHighlight(dou, ['title'], 'Não Informado')
  }, [])

  const content = useMemo(() => {
    return formatterHighlight(dou, ['content'], 'Não Informado')
  }, [])

  return (
    <CardTemplate
      content={[
        <>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            spacing={2}
          >
            <Chip
              size="small"
              color="primary"
              variant="outlined"
              sx={{ paddingX: 1 }}
              label={
                <Typography
                  variant="$font-body-3"
                  color="$color-action-text-secundary"
                >
                  {labelChip || 'Não Informado'}
                </Typography>
              }
            />
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography color="$color-text-secundary" variant="$font-body-2">
                {hierarchy[0]}
              </Typography>
              {hierarchy[1] && (
                <KeyboardArrowRight
                  sx={{
                    color: theme.palette['$color-border-tertiary'],
                    fontSize: 16,
                  }}
                />
              )}
              <Typography color="$color-text-secundary" variant="$font-body-2">
                {hierarchy[1]}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography color="$color-text-primary" variant="$font-body-1">
              {title}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextWrap lines={2}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secundary"
              >
                {content}
              </Typography>
            </TextWrap>
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ md: 'center' }}
            spacing={2}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography color="$color-text-secundary" variant="$font-body-2">
                Data de publicação:
              </Typography>
              <Typography color="$color-text-primary" variant="$font-body-2">
                {formatDate(dou.date) || 'Não Informado'}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography color="$color-text-secundary" variant="$font-body-2">
                Edição:
              </Typography>
              <Typography color="$color-text-primary" variant="$font-body-2">
                {dou.location || 'Não Informado'}
              </Typography>
            </Stack>
          </Stack>
        </>,
      ]}
      actions={[
        <SaveBucketButton
          id={dou?.id}
          bucket={BucketsEnum.dou}
          isSaved={dou?.savedBuckets?.length > 0}
          openDataResource="dou"
          onCreatedSavedBucket={handleAddSavedBucket}
          onRemovedSavedBucket={handleRemoveSavedBucket}
        />,
        <Button
          text={<Typography variant="$font-body-2">Detalhes</Typography>}
          size="medium"
          icon="arrow"
          sx={{ height: '34px', m: 0 }}
          href={url}
          target="_blank"
        />,
      ]}
    />
  )
}
