import produce from 'immer'
import { useCallback, useMemo, useState } from 'react'
import { useDrawer } from '@contexts/Drawer'
import { Button, TextWrap } from '@components/atoms'
import { HouseInfo, Status } from '@components/molecules'
import { MonitoringDrawer } from '@containers/SharedDrawers'
import { Stack, IconButton, Typography } from '@mui/material'
import {
  formatDate,
  formatterHighlight,
} from '@utils/functions/formatter/formatter'
import { capitalizeName } from '@utils/functions/capitalize/capitalize'
import {
  PropositionEntity,
  PropositionProcessingLegend,
} from '@services/nomos_api/entities/proposition'

import { PushPinRounded, PushPinOutlined } from '@mui/icons-material'

import {
  CardStatus,
  CardRow,
  TextDescriptor,
  TextChip,
  CountChip,
  WrapChip,
  Tooltip,
} from './styles'
import _ from 'lodash'
import { CardTemplate } from '../CardTemplate'
import { BucketsEnum } from '@utils/buckets/type'
import { useSearchResultsUrl } from '@hooks/useSearchResultsUrl'
import { SavedPropositionEntity } from '@services/nomos_api/entities/saved_proposition'

type Props = { proposition: PropositionEntity }

export function CardProposition({ proposition: stateProposition }: Props) {
  const { openDrawer } = useDrawer()
  const [proposition, setProposition] = useState(stateProposition)
  const { url } = useSearchResultsUrl(`/propositions/${proposition.id}`)
  const author = proposition.authors ? proposition.authors[0] : {}
  const rapporteur = proposition.rapporteurs ? proposition.rapporteurs[0] : {}

  const updatedAt = formatDate(proposition?.lastProceeding?.date)

  const labelChipAuthor = [
    _.get(author, 'type'),
    capitalizeName(_.get(author, 'name')),
    _.get(author, 'acronymParty'),
  ]
    .filter((i) => !!i)
    .join(' - ')

  const labelChipRapporteur = _.get(rapporteur, 'name')
    ? [
        _.get(rapporteur, 'type'),
        capitalizeName(_.get(rapporteur, 'name')),
        _.get(rapporteur, 'acronymParty'),
      ]
        .filter((i) => !!i)
        .join(' - ')
    : 'Não designado'

  const authors =
    proposition?.authors &&
    proposition.authors.length > 1 &&
    proposition.authors
      .map((item) => {
        const name = [
          _.get(item, 'type'),
          capitalizeName(_.get(item, 'name')),
          _.get(item, 'acronymParty'),
        ]
          .filter((i) => !!i)
          .join(' - ')

        return name
      })
      .slice(1)
      .join(', ')

  const rapporteurs =
    proposition?.rapporteurs &&
    proposition.rapporteurs.length > 1 &&
    proposition.rapporteurs
      .map((item) => {
        const name = [
          _.get(item, 'type'),
          capitalizeName(_.get(item, 'name')),
          _.get(item, 'acronymParty'),
        ]
          .filter((i) => !!i)
          .join(' - ')

        return name
      })
      .slice(1)
      .join(', ')

  const handleAddSavedProposition = useCallback(
    (savedPropositions?: SavedPropositionEntity[]) => {
      setProposition(
        produce(proposition, (draft) => {
          if (draft.savedPropositions) {
            draft.savedPropositions.push(...(savedPropositions || []))
          }
        })
      )
    },
    [setProposition, proposition]
  )

  const handleRemoveSavedProposition = useCallback(
    (removedIds: string[]) => {
      setProposition({
        ...proposition,
        savedPropositions: (proposition?.savedPropositions || [])?.filter(
          (sp) => !removedIds.includes(sp.id)
        ),
      })
    },
    [setProposition, proposition]
  )

  const formatText = (text: string) => {
    if (!text || text === 'undefined') return '--'
    return text
  }

  const title = useMemo(() => {
    return formatterHighlight(proposition, ['title'])
  }, [])

  const summary = useMemo(() => {
    return formatterHighlight(proposition, ['summary'])
  }, [])

  return (
    <CardTemplate
      content={[
        <>
          <CardRow>
            <CardStatus>
              <Status
                text={_.get(
                  PropositionProcessingLegend,
                  `${_.get(proposition, 'lastProceeding.processing')}.text`
                )}
                color={_.get(
                  PropositionProcessingLegend,
                  `${_.get(proposition, 'lastProceeding.processing')}.color`
                )}
              />
            </CardStatus>
            <HouseInfo text={proposition.openDataResource} />
          </CardRow>
          <CardRow>
            <Tooltip
              arrow
              title={formatText(proposition.type)}
              placement="top-start"
            >
              <div>
                <Typography
                  noWrap
                  variant="$font-body-4"
                  color="$color-text-primary"
                >
                  {title}
                </Typography>
              </div>
            </Tooltip>
            <TextDescriptor>
              <Typography
                variant="$font-body-2"
                color="$color-text-secundary"
                noWrap
              >
                Atualizado em:
              </Typography>
              <Tooltip arrow title={formatText(updatedAt)} placement="top">
                <div>
                  <Typography
                    noWrap
                    variant="$font-body-2"
                    color="$color-text-primary"
                  >
                    {formatText(updatedAt)}
                  </Typography>
                </div>
              </Tooltip>
            </TextDescriptor>

            <TextDescriptor>
              <Typography variant="$font-body-2" color="$color-text-secundary">
                Situação:
              </Typography>
              <Tooltip
                arrow
                title={
                  proposition?.lastProceeding?.situation ||
                  proposition?.lastProceeding?.description ||
                  'Não Informado'
                }
                placement="top"
              >
                <div>
                  <TextWrap lines={1}>
                    <Typography
                      variant="$font-body-2"
                      color="$color-text-primary"
                    >
                      {proposition?.lastProceeding?.situation ||
                        proposition?.lastProceeding?.description ||
                        'Não Informado'}
                    </Typography>
                  </TextWrap>
                </div>
              </Tooltip>
            </TextDescriptor>
          </CardRow>

          <CardRow>
            <TextWrap lines={3}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secundary"
              >
                <strong>Ementa: </strong>
                {summary}
              </Typography>
            </TextWrap>
          </CardRow>

          <CardRow>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              flexWrap="wrap"
            >
              <Typography variant="$font-body-2" color="$color-text-secundary">
                Autor:
              </Typography>
              <Tooltip
                arrow
                title={formatText(labelChipAuthor)}
                placement="top"
              >
                <WrapChip
                  size="small"
                  label={<TextChip>{formatText(labelChipAuthor)}</TextChip>}
                />
              </Tooltip>

              {proposition?.authors?.length > 1 && (
                <Tooltip arrow title={authors} placement="top">
                  <CountChip label={`+${proposition.authors.length - 1}`} />
                </Tooltip>
              )}
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              flexWrap="wrap"
            >
              <Typography variant="$font-body-2" color="$color-text-secundary">
                Relator:
              </Typography>

              <Tooltip
                arrow
                title={formatText(labelChipRapporteur)}
                placement="top"
              >
                <WrapChip
                  size="small"
                  label={<TextChip>{formatText(labelChipRapporteur)}</TextChip>}
                />
              </Tooltip>

              {(proposition?.rapporteurs || []).length > 1 && (
                <Tooltip arrow title={String(rapporteurs)} placement="top">
                  <CountChip
                    label={`+${(proposition?.rapporteurs || []).length - 1}`}
                  />
                </Tooltip>
              )}
            </Stack>
          </CardRow>
        </>,
      ]}
      actions={[
        <Tooltip arrow title="Monitorar proposição" placement="top-end">
          <IconButton
            data-cy="button-monitor-proposition"
            onClick={() =>
              openDrawer(
                <MonitoringDrawer
                  id={proposition?.id}
                  origin={BucketsEnum.propositions}
                  house={proposition?.openDataResource}
                  isSaved={(proposition?.savedPropositions || []).length > 0}
                  onFinish={handleAddSavedProposition}
                  onRemove={handleRemoveSavedProposition}
                />
              )
            }
          >
            {proposition?.savedPropositions?.length ? (
              <PushPinRounded sx={{ color: '#0C2256' }} />
            ) : (
              <PushPinOutlined sx={{ color: '#0C2256' }} />
            )}
          </IconButton>
        </Tooltip>,
        <Button
          text={<Typography variant="$font-body-2">Detalhes</Typography>}
          size="medium"
          icon="arrow"
          sx={{ height: '34px', m: 0 }}
          href={url}
          target="_blank"
        />,
      ]}
    />
  )
}
