import produce from 'immer'
import { useCallback, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { CardTemplate } from '../CardTemplate'
import { Stack, Typography } from '@mui/material'
import { BucketsEnum } from '@utils/buckets/type'
import { LaunchOutlined } from '@mui/icons-material'
import { Content, Picture, PictureContainer } from './styles'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import { useSearchResultsUrl } from '@hooks/useSearchResultsUrl'
import { NoticeEntity } from '@services/nomos_api/entities/notice'
import {
  formatDateTime,
  formatterHighlight,
} from '@utils/functions/formatter/formatter'
import { Button, SaveBucketButton, TextWrap } from '@components/atoms'

import g1 from '@assets/images/g1.png'
import r7 from '@assets/images/r7.png'
import cnn from '@assets/images/cnn.png'
import stj from '@assets/images/stj.png'
import folha from '@assets/images/folha.png'
import gazeta from '@assets/images/gazeta.png'
import senado from '@assets/images/senado.png'
import camara from '@assets/images/camara.png'

export type CardNoticeProps = { notice: NoticeEntity }

const images = {
  g1,
  r7,
  cnn,
  stj,
  folha,
  gazeta,
  senado,
  camara,
} as Record<string, string>

export function CardNotice({ notice: noticeState }: CardNoticeProps) {
  const [notice, setNotice] = useState<NoticeEntity>(noticeState)
  const { url } = useSearchResultsUrl(`/notices/${notice.id}`)

  const handleAddSavedBucket = useCallback(
    (savedbuckets: SavedBucketEntity[]) => {
      setNotice(
        produce(notice, (draft) => {
          draft.savedBuckets.push(...savedbuckets)
        })
      )
    },
    [setNotice, notice]
  )

  const handleRemoveSavedBucket = useCallback(
    (removedIds: string[]) => {
      setNotice({
        ...notice,
        savedBuckets: notice.savedBuckets.filter(
          (sb) => !removedIds.includes(sb.id)
        ),
      })
    },
    [setNotice, notice]
  )

  const title = useMemo(() => {
    return formatterHighlight(notice, ['title'], 'Não Informado')
  }, [])

  const content = useMemo(() => {
    return formatterHighlight(
      notice,
      ['description', 'content'],
      'Sem conteúdo'
    )
  }, [])

  return (
    <CardTemplate
      content={[
        <Content>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            sx={{ flex: 1 }}
          >
            <PictureContainer>
              <Picture
                src={notice.image || images[notice.openDataResource]}
                alt={notice.date}
                loading="lazy"
              />
            </PictureContainer>
            <Content>
              <Typography
                variant="$font-body-base"
                color="$color-text-secundary"
              >
                {formatDateTime(notice.date)}
              </Typography>
              <Stack direction="column" spacing={1}>
                <Typography variant="$font-title-5" color="$color-text-primary">
                  {title}
                </Typography>
                <TextWrap lines={2}>
                  <Typography
                    variant="$font-body-base"
                    color="$color-text-secundary"
                  >
                    {content}
                  </Typography>
                </TextWrap>
              </Stack>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ md: 'center' }}
                spacing={1}
              >
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  Fonte:
                </Typography>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {notice.site}
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {!isMobile && (
                    <Button
                      variant="text"
                      size="medium"
                      sx={{ p: { xs: 0, md: 1 }, whiteSpace: 'nowrap' }}
                      endIcon={<LaunchOutlined />}
                      text="Acompanhe na Íntegra"
                      href={notice?.url}
                    />
                  )}
                </Stack>
              </Stack>
            </Content>
          </Stack>
        </Content>,
      ]}
      actions={[
        <SaveBucketButton
          id={notice?.id}
          bucket={BucketsEnum.notices}
          isSaved={notice?.savedBuckets?.length > 0}
          openDataResource={notice?.openDataResource}
          onCreatedSavedBucket={handleAddSavedBucket}
          onRemovedSavedBucket={handleRemoveSavedBucket}
        />,
        <Button
          text={<Typography variant="$font-body-2">Detalhes</Typography>}
          size="medium"
          icon="arrow"
          sx={{ height: '34px', m: 0 }}
          href={url}
          target="_blank"
        />,
      ]}
    />
  )
}
