import options from './options'
import { ScheduleContext } from '../../context'
import { SideFilterSelect } from '@components/organisms'
import { useContextSelector } from 'use-context-selector'
import { List, ListItem, Typography } from '@mui/material'

import {
  ColoredIcon,
  FilterContainer,
  FilterTitle,
  ScheduleLabel,
  ScheduleLabelContainer,
} from './AddSchedule.styles'

function AddSchedule() {
  const handleUpdateSchedule = useContextSelector(
    ScheduleContext,
    (s) => s.handleUpdateSchedule
  )

  return (
    <FilterContainer>
      <FilterTitle>Adicionar agenda:</FilterTitle>

      <ScheduleLabelContainer>
        <ScheduleLabel>
          <ColoredIcon color="#0A2156" />
          <Typography variant="$font-body-2" color="$color-text-secundary">
            Minha Agenda
          </Typography>
        </ScheduleLabel>

        <ScheduleLabel>
          <ColoredIcon color="#6D7FAC" />
          <Typography variant="$font-body-2" color="$color-text-secundary">
            Outras
          </Typography>
        </ScheduleLabel>
      </ScheduleLabelContainer>

      <List>
        {options?.map((filter) => (
          <ListItem key={filter.attr} disablePadding>
            <SideFilterSelect
              filter={filter}
              handleUpdateSearch={handleUpdateSchedule}
            />
          </ListItem>
        ))}
      </List>
    </FilterContainer>
  )
}

export default AddSchedule
