import EditMonitor from '../EditMonitor'
import { useAuth } from '@contexts/Auth'
import { useModal } from '@contexts/Modal'
import { Button } from '@components/atoms'
import { useDrawer } from '@contexts/Drawer'
import { MonitorContext } from '../../context'
import { isMobile } from 'react-device-detect'
import { CaretDown } from '@phosphor-icons/react'
import { BucketsEnum } from '@utils/buckets/type'
import CloneMonitorModal from '../CloneMonitorModal'
import { ModalMonitorReport } from '../ModalReport'
import { useEffect, useMemo, useState } from 'react'
import { useContextSelector } from 'use-context-selector'
import { Stack, Typography, Menu, MenuItem } from '@mui/material'
import { NoteManagerDrawer } from '@containers/NoteManagerDrawer'

import EditIcon from '@assets/icons/edit.svg'
import CloneIcon from '@assets/icons/clone.svg'
import NotesBackIcon from '@assets/icons/notes-black.svg'
import DownloadFileIcon from '@assets/icons/download-file.svg'

export function Actions(): JSX.Element {
  const { user } = useAuth()
  const { openModal } = useModal()
  const { openDrawer } = useDrawer()

  const clone = useContextSelector(MonitorContext, (s) => s.clone)

  const handleCloneMonitor = useContextSelector(
    MonitorContext,
    (s) => s.handleCloneMonitor
  )

  const { data: monitor, isLoading } = useContextSelector(
    MonitorContext,
    (s) => s.monitor
  )

  const [anchorPanelOptions, setAnchorPanelOptions] = useState<
    Element | null | undefined
  >(null)

  const handleUpdateMonitor = useContextSelector(
    MonitorContext,
    (s) => s.handleUpdateMonitor
  )

  const handleExcludeMonitor = useContextSelector(
    MonitorContext,
    (s) => s.handleExcludeMonitor
  )

  const isSuggestion = monitor?.suggested

  const shouldShowCompanyControl = useMemo(() => {
    return !monitor?.suggested || monitor?.companyId === user?.companyId
  }, [monitor])

  const openCloneModal = () => {
    openModal(<CloneMonitorModal onConfirm={handleCloneMonitor} />)
  }

  useEffect(() => {
    if (clone) openCloneModal()
  }, [clone])

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {!isMobile && (
        <Button
          text="Relatório"
          variant="contained"
          disabled={isLoading}
          endIcon={<img src={DownloadFileIcon} alt="Relatório" />}
          onClick={() =>
            openModal(<ModalMonitorReport monitorId={monitor.id} />)
          }
        />
      )}

      <Button
        id="options-panel-btn"
        text="Ações do Painel"
        color="primary"
        variant="outlined"
        endIcon={<CaretDown />}
        disabled={isLoading}
        onClick={(event) => setAnchorPanelOptions(event.currentTarget)}
      />
      <Menu
        id="menu-appbar"
        sx={{ mt: '45px' }}
        anchorEl={anchorPanelOptions}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorPanelOptions)}
        onClose={() => setAnchorPanelOptions(null)}
      >
        {shouldShowCompanyControl && (
          <MenuItem
            data-testid="edit-panel-btn"
            onClick={() => {
              setAnchorPanelOptions(null)
              openDrawer(
                <EditMonitor
                  monitor={monitor}
                  onDeleteMonitor={handleExcludeMonitor}
                  onSavedMonitor={handleUpdateMonitor}
                />
              )
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <img src={EditIcon} alt="Editar Painel" />
              <Typography
                variant="$font-body-base"
                color="$color-text-secundary"
              >
                Editar Painel
              </Typography>
            </Stack>
          </MenuItem>
        )}
        {shouldShowCompanyControl && (
          <MenuItem
            onClick={() => {
              setAnchorPanelOptions(null)
              openDrawer(
                <NoteManagerDrawer
                  id={monitor.id}
                  bucket={BucketsEnum.monitors}
                />
              )
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <img src={NotesBackIcon} alt="Notas" />
              <Typography
                variant="$font-body-base"
                color="$color-text-secundary"
              >
                Notas
              </Typography>
            </Stack>
          </MenuItem>
        )}
        {isSuggestion && (
          <MenuItem
            onClick={() => {
              setAnchorPanelOptions(null)
              openCloneModal()
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <img src={CloneIcon} alt="Clonar Painel" />
              <Typography
                variant="$font-body-base"
                color="$color-text-secundary"
              >
                Clonar Painel
              </Typography>
            </Stack>
          </MenuItem>
        )}
      </Menu>
    </Stack>
  )
}
