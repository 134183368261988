import {
  Box,
  Typography,
  Chip,
  Tooltip as MUITooltip,
  tooltipClasses,
  styled,
  TooltipProps,
} from '@mui/material'

export const CardStatus = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`

export const CardRow = styled(Box)`
  gap: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const TextDescriptor = styled(Box)`
  gap: 6px;
  display: flex;
  align-items: center;
`

export const TextNoWrap = styled(Typography)`
  white-space: nowrap;
`

export const TextOverflow = styled(Typography)`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const TextChip = styled((props) => (
  <Typography {...props} variant="$font-body-3" color="$color-text-primary" />
))`
  max-width: 400px;
`

export const WrapChip = styled(Chip)`
  background-color: ${({ theme }) =>
    theme.palette['$color-background-tertiary']};
  padding-left: 8px;
  padding-right: 8px;
`

export const CountChip = styled(Chip)`
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) =>
    theme.palette['$color-background-alternative-dark']};
  & > span {
    padding-left: 6px;
    padding-right: 6px;
  }
`

export const Tooltip = styled(({ ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: props.className }} />
))`
  & .${tooltipClasses.tooltip} {
    padding: 8px;
    background-color: #0d3549;
    border-radius: 4px;
  }
  & .${tooltipClasses.arrow} {
    color: #0d3549;
  }
`
