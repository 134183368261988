import colors from '@theme/colors'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import { Card } from '@components/atoms'

export const MonitorCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 15px;
`

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5rem 0;
  height: auto;
  gap: 14px;
  background-color: ${colors['$color-background-secundary']};
`

export const DashboardContent = styled(Grid)`
  padding-top: 70px;
`

export const DashboardCards = styled(Grid)``

export const CardTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

export const CardContentSum = styled.div`
  display: flex;
  align-self: center;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  svg {
    display: none;
  }

  @media (min-width: 768px) {
    gap: 42px;
    svg {
      display: block;
    }
  }
`
