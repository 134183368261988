/* eslint-disable object-shorthand */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import _ from 'lodash'
import produce from 'immer'
import { useLocation } from 'react-router-dom'
import { SearchResultsContext } from './context'
import { PagiantionType } from '@type/pagination'
import { useQueryState } from '@hooks/useQueryState'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { splitter } from '@utils/functions/splitter/splitter'
import { SearchReulstsControllerProps, SearchType } from './types'
import { useSearchDou } from '@services/nomos_api/resources/search/dou'
import { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { useSearchCvmMutation } from '@services/nomos_api/resources/search/cvm'
import { useSearchSpeeches } from '@services/nomos_api/resources/search/speeches'
import { useSearchSocialMutation } from '@services/nomos_api/resources/search/social'
import { useSearchEventsMutation } from '@services/nomos_api/resources/search/events'
import { useSearchNoticesMutation } from '@services/nomos_api/resources/search/notices'
import { useSearchPropositionsMutation } from '@services/nomos_api/resources/search/propositions'
import {
  decodeKeywords,
  encodeKeywords,
} from '@utils/functions/keyword/serialize'

import {
  BucketIrs,
  BucketCvm,
  BucketDou,
  BucketBacen,
  BucketEvents,
  BucketSocial,
  BucketNotices,
  BucketSpeeches,
  BucketPropositions,
  createBlankBucketFilter,
  BucketOficialDiary,
  BucketRegulatoryAgencies,
} from '@utils/buckets'

import { isMobile } from 'react-device-detect'
import { useSnackbar } from '@contexts/Snackbar'
import { BucketsEnum } from '@utils/buckets/type'
import { AxiosErrorResponse } from '@services/nomos_api/entities/axios'
import { removeEmptyArrays } from '@utils/functions/normalizers/remove_empty_array'
import { useSearchBacenMutation } from '@services/nomos_api/resources/search/bacen'
import { updateMonitorSearchAPI } from '@services/nomos_api/resources/monitor_search/update'
import { useSearchOficialDiary } from '@services/nomos_api/resources/search/oficial_diary'
import { useSearchIrsMutation } from '@services/nomos_api/resources/search/irs'
import { useSearchRegulatoryAgenciesMutation } from '@services/nomos_api/resources/search/regulatory_agencies'

// **** all available buckets object with ****
// **** themself names and filters        ****
const mappedBuckets = isMobile
  ? [
      BucketNotices,
      BucketSocial,
      BucketEvents,
      BucketPropositions,
      BucketDou,
      BucketOficialDiary,
      BucketSpeeches,
      BucketBacen,
      BucketCvm,
      BucketIrs,
      BucketRegulatoryAgencies,
    ]
  : [
      BucketPropositions,
      BucketEvents,
      BucketDou,
      BucketOficialDiary,
      BucketSocial,
      BucketNotices,
      BucketSpeeches,
      BucketBacen,
      BucketCvm,
      BucketIrs,
      BucketRegulatoryAgencies,
    ]

type QueryStateType = {
  keyword: string
  buckets: string
  modes: string
  searchId?: string
  monitorId?: string
  resumeSearch?: string
}

export function SearchResultsController({
  children,
}: SearchReulstsControllerProps) {
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()
  const [saving, setSaving] = useState<boolean>(false)
  const [resumeSearch, setResumeSearch] = useState<boolean>(false)

  const {
    data: dataCvm,
    isLoading: loadingCvm,
    mutate: mutateCvm,
  } = useSearchCvmMutation()

  const {
    data: dataIrs,
    isLoading: loadingIrs,
    mutate: mutateIrs,
  } = useSearchIrsMutation()

  const {
    data: dataRegulatoryAgencies,
    isLoading: loadingRegulatoryAgencies,
    mutate: mutateRegulatoryAgencies,
  } = useSearchRegulatoryAgenciesMutation()

  const {
    data: dataDous,
    isLoading: loadingDou,
    mutate: mutateDou,
  } = useSearchDou()

  const {
    data: dataOficialDiary,
    isLoading: loadingOficialDiary,
    mutate: mutateOficialDiary,
  } = useSearchOficialDiary()

  const {
    data: dataBacen,
    isLoading: loadingBacen,
    mutate: mutateBacen,
  } = useSearchBacenMutation()

  const {
    data: dataEvents,
    isLoading: loadingEvents,
    mutate: mutateEvents,
  } = useSearchEventsMutation()
  const {
    data: dataPropositions,
    isLoading: loadingPropositions,
    mutate: mutatePropositions,
  } = useSearchPropositionsMutation()
  const {
    data: dataSpeeches,
    isLoading: loadingSpeeches,
    mutate: mutateSpeeches,
  } = useSearchSpeeches()
  const {
    data: dataNotices,
    isLoading: loadingNotices,
    mutate: mutateNotices,
  } = useSearchNoticesMutation()
  const {
    data: dataSocial,
    isLoading: loadingSocial,
    mutate: mutateSocial,
  } = useSearchSocialMutation()

  // **** get state from location to access filters   ****
  // **** and keywords if provided by last route      ****
  const { state } = useLocation()

  // **** get initial state from url that user wants  ****
  // **** to display on screen                        ****
  const [{ keyword, buckets, modes, searchId, monitorId }, setQueryState] =
    useQueryState<QueryStateType>()

  const availableBuckets = !buckets
    ? mappedBuckets.map((bucket) => bucket.key).join(',')
    : buckets

  const keywords = useMemo(() => decodeKeywords(keyword as string), [keyword])

  // **** define default filter based on provided state         ****
  // **** that cames from other pages (home or saved-searches)  ****
  const defaultFilters = useMemo(() => {
    const defaultFilter = {
      keywords: decodeKeywords(keyword),
      id: searchId,
      monitorId,
      modes: splitter(modes),
      buckets: {},
    }

    // only add filter to bucket if it was provided by state
    // filter attached to buckets is responsible to reflect
    // it on URL and render visible bucket tabs on screen
    const initialBuckets = splitter(availableBuckets)

    if (initialBuckets.includes('propositions')) {
      _.set(
        defaultFilter,
        'buckets.propositions',
        createBlankBucketFilter(_.get(state, 'filters.propositions', {}))
      )
    }

    if (initialBuckets.includes('events')) {
      _.set(
        defaultFilter,
        'buckets.events',
        createBlankBucketFilter(_.get(state, 'filters.events', {}))
      )
    }

    if (initialBuckets.includes('cvm')) {
      _.set(
        defaultFilter,
        'buckets.cvm',
        createBlankBucketFilter(_.get(state, 'filters.cvm', {}))
      )
    }

    if (initialBuckets.includes('irs')) {
      _.set(
        defaultFilter,
        'buckets.irs',
        createBlankBucketFilter(_.get(state, 'filters.irs', {}))
      )
    }

    if (initialBuckets.includes('regulatory_agencies')) {
      _.set(
        defaultFilter,
        'buckets.regulatory_agencies',
        createBlankBucketFilter(_.get(state, 'filters.regulatory_agencies', {}))
      )
    }

    if (initialBuckets.includes('dou')) {
      _.set(
        defaultFilter,
        'buckets.dou',
        createBlankBucketFilter(_.get(state, 'filters.dou', {}))
      )
    }

    if (initialBuckets.includes('oficial_diary')) {
      _.set(
        defaultFilter,
        'buckets.oficial_diary',
        createBlankBucketFilter(_.get(state, 'filters.oficial_diary', {}))
      )
    }

    if (initialBuckets.includes('bacen')) {
      _.set(
        defaultFilter,
        'buckets.bacen',
        createBlankBucketFilter(_.get(state, 'filters.bacen', {}))
      )
    }

    if (initialBuckets.includes('social')) {
      _.set(
        defaultFilter,
        'buckets.social',
        createBlankBucketFilter(_.get(state, 'filters.social', {}))
      )
    }

    if (initialBuckets.includes('notices')) {
      _.set(
        defaultFilter,
        'buckets.notices',
        createBlankBucketFilter(_.get(state, 'filters.notices', {}))
      )
    }

    if (initialBuckets.includes('speeches')) {
      _.set(
        defaultFilter,
        'buckets.speeches',
        createBlankBucketFilter(_.get(state, 'filters.speeches', {}))
      )
    }

    return defaultFilter
  }, [state])

  const [filterVisible, setFilterVisible] = useState(false)
  const [selectedBucket, setSelectedBucket] = useState<BucketsEnum>(
    (isMobile && availableBuckets.includes('notices')
      ? 'notices'
      : splitter(availableBuckets)[0]) as BucketsEnum
  )

  const [search, setSearch] = useLocalStorage(
    '@NOMOS:defaultFilters',
    defaultFilters
  )

  const [pagination, setPagination] = useState<PagiantionType>({
    limit: 0,
    pages: 0,
    page: 0,
    total: 0,
  })

  const toggleFilter = useCallback(
    () => setFilterVisible(!filterVisible),
    [filterVisible]
  )

  const toggleResumeSearch = useCallback(
    (value: string) => {
      setResumeSearch(value === '1')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updatedSearchState = produce(search, (draft: any) => {
        draft.resumeSearch = value === '1'
      })

      setSearch(updatedSearchState)

      setQueryState({
        modes: search.modes.join(','),
        keyword: encodeKeywords(search.keywords),
        buckets: Object.keys(search.buckets).join(','),
        resumeSearch: value === '1' ? '1' : '0',
      })
    },
    [search]
  )

  const handleChangeBucket = useCallback(
    (bucket) => {
      setSelectedBucket(bucket)

      if (!buckets) return bucket

      const filteredBuckets = buckets
        .split(',')
        .filter((b) => b !== bucket)
        .filter((b) => b !== '')

      if (filteredBuckets.length === 0) return bucket

      const updatedBuckets = [bucket, ...filteredBuckets].join(',')

      setQueryState({ buckets: updatedBuckets })

      return updatedBuckets
    },
    [buckets, keyword, keywords, modes]
  )

  // **** bucket objects filtered by picked ****
  // **** bucket to display on tabs.        ****
  const activeBuckets = useMemo(
    () =>
      mappedBuckets.filter((bucket) =>
        splitter(availableBuckets).includes(bucket.key)
      ),
    [buckets]
  )

  const inactiveBuckets = useMemo(
    () =>
      mappedBuckets.filter(
        (bucket) => !splitter(availableBuckets).includes(bucket.key)
      ),
    [buckets]
  )

  // **** selected tab bucket object ****
  const bucket = useMemo(
    () => mappedBuckets.find((bucket) => bucket.key === selectedBucket),
    [selectedBucket]
  )

  // **** update filter throught dotnotation      ****
  // **** should be the unique way used by child  ****
  // **** components to update search state.      ****
  const handleUpdateSearch = useCallback(
    (object: Partial<SearchType>, keepPage = false) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updatedSearch = produce(search, (draft: any) => {
        // update filter by dot notation
        // eslint-disable-next-line guard-for-in
        for (const key in object) {
          _.set(draft, key, _.get(object, key))
        }
        // force keepPage if paginating
        // otherelse, reinitialize pagination for new filter results
        if (!keepPage) _.set(draft, `buckets.${selectedBucket}.page`, 1)
      })
      setSearch(updatedSearch)
    },
    [search, selectedBucket]
  )

  const handleMountBucketState = useCallback(
    (buckets: BucketsEnum[]) => {
      const updatedbuckets: Partial<Record<BucketsEnum, object>> = {}
      // add selected buckets
      for (const bucketKey of buckets) {
        if (!updatedbuckets[bucketKey]) {
          updatedbuckets[bucketKey] = createBlankBucketFilter({})
        }
      }
      // remove non selected buckets
      for (const bucketKey in search.buckets) {
        if (buckets.includes(bucketKey as BucketsEnum)) {
          updatedbuckets[bucketKey as BucketsEnum] = search.buckets[bucketKey]
        }
      }
      return updatedbuckets
    },
    [search]
  )

  // **** add new bucket on url to enable it  ****
  // **** as a tab on screen.                 ****
  const handleAddBucket = useCallback(
    (bucketKey) => {
      if (Object.keys(search.buckets).includes(bucketKey)) return
      handleUpdateSearch({
        buckets: handleMountBucketState([
          ...Object.keys(search.buckets),
          bucketKey,
        ]),
      })
    },
    [buckets, search]
  )

  // **** remove bucket search state    ****
  // **** and mirror it on browser url  ****
  const handleRemoveBucket = useCallback(
    (bucketKey) => {
      if (!Object.keys(search.buckets).includes(bucketKey)) return
      handleUpdateSearch({
        buckets: handleMountBucketState(
          Object.keys(search.buckets).filter(
            (b) => b !== bucketKey
          ) as BucketsEnum[]
        ),
      })
    },
    [buckets, search]
  )

  const handleUpdateSavedSearch = useCallback(async () => {
    const { buckets, keywords, ...payload } = search

    // add only selected buckets
    const updatedBuckets: Record<string, { filter: object }> = {}
    Object.keys(buckets || {}).forEach((key) => {
      if (!updatedBuckets[key]) updatedBuckets[key] = { filter: {} }
      if (buckets[key].filter) {
        updatedBuckets[key as BucketsEnum] = {
          filter: buckets[key]?.filter || {},
        }
      }
    })

    setSaving(true)
    // updateMonitorSearchAPI({
    updateMonitorSearchAPI({
      ...payload,
      keywords: removeEmptyArrays(keywords || {}),
      buckets: updatedBuckets,
    })
      .then(() => {
        showSnackbarSuccess('Pesquisa atualizada com sucesso')
      })
      .catch((e) => {
        const error = e as AxiosErrorResponse
        showSnackbarError(
          error?.response?.data?.message ||
            'Não foi possível completar a operação'
        )
      })
      .finally(() => setSaving(false))
  }, [search])

  // **** everytime the search is updated   ****
  // **** reflect this state to browser url ****
  const firstrender = useRef(true)
  useEffect(() => {
    if (firstrender.current) {
      firstrender.current = false
      return
    }
    setQueryState({
      modes: search.modes.join(','),
      keyword: encodeKeywords(search.keywords),
      buckets: Object.keys(search.buckets).join(','),
    })
  }, [search])

  // **** fetch bucket data everytime a keyword or bucket changes ****
  useEffect(() => {
    const mutations = {
      irs: mutateIrs,
      cvm: mutateCvm,
      dou: mutateDou,
      events: mutateEvents,
      propositions: mutatePropositions,
      oficial_diary: mutateOficialDiary,
      speeches: mutateSpeeches,
      notices: mutateNotices,
      social: mutateSocial,
      bacen: mutateBacen,
      regulatory_agencies: mutateRegulatoryAgencies,
    }
    if (_.has(mutations, selectedBucket))
      _.get(
        mutations,
        selectedBucket
      )(
        _.omitBy(
          {
            searchId: search.id,
            modes: search.modes,
            keyword: search.keyword,
            keywords: search.keywords,
            sort: search.buckets?.[selectedBucket]?.sort,
            page: search.buckets?.[selectedBucket]?.page,
            limit: search.buckets?.[selectedBucket]?.limit,
            filter: search.buckets?.[selectedBucket]?.filter,
            resumeSearch: resumeSearch,
          },
          _.isNull
        )
      )
  }, [selectedBucket, search])

  // **** update pagination state everytime a fetch is made to api ****
  useEffect(() => {
    const pages = {
      irs: dataIrs,
      cvm: dataCvm,
      dou: dataDous,
      bacen: dataBacen,
      events: dataEvents,
      propositions: dataPropositions,
      oficial_diary: dataOficialDiary,
      speeches: dataSpeeches,
      notices: dataNotices,
      social: dataSocial,
      regulatory_agencies: dataRegulatoryAgencies,
    }
    if (_.has(pages, selectedBucket)) {
      setPagination(_.get(pages, selectedBucket)?.pagination)
    }
  }, [
    dataIrs,
    dataCvm,
    dataDous,
    dataBacen,
    dataEvents,
    dataPropositions,
    dataOficialDiary,
    dataSpeeches,
    dataNotices,
    dataSocial,
    dataRegulatoryAgencies,
  ])

  // **** compound an unique loading state based on any bucket fetch loading state ****
  const isLoading = useMemo(
    () =>
      loadingCvm ||
      loadingIrs ||
      loadingDou ||
      loadingBacen ||
      loadingPropositions ||
      loadingOficialDiary ||
      loadingSpeeches ||
      loadingNotices ||
      loadingSocial ||
      loadingEvents ||
      loadingRegulatoryAgencies,
    [
      loadingCvm,
      loadingIrs,
      loadingDou,
      loadingBacen,
      loadingEvents,
      loadingSpeeches,
      loadingNotices,
      loadingSocial,
      loadingPropositions,
      loadingOficialDiary,
      loadingRegulatoryAgencies,
    ]
  )

  // **** provide all data states to view layer ****
  const store = useMemo(
    () => ({
      saving,
      modes: splitter(modes) as 'sensitive'[],
      search,
      keyword,
      keywords,
      dataIrs,
      dataCvm,
      dataDous,
      dataBacen,
      dataEvents,
      dataPropositions,
      dataOficialDiary,
      dataSpeeches,
      dataNotices,
      dataSocial,
      dataRegulatoryAgencies,
      bucket,
      buckets: splitter(buckets) as BucketsEnum[],
      activeBuckets,
      inactiveBuckets,
      selectedBucket,
      isLoading,
      pagination,
      filterVisible,
      toggleFilter,
      resumeSearch,
      toggleResumeSearch,
      setSelectedBucket,
      handleChangeBucket,
      handleAddBucket,
      handleRemoveBucket,
      handleUpdateSearch,
      handleMountBucketState,
      handleUpdateSavedSearch,
    }),
    [
      saving,
      modes,
      search,
      keyword,
      keywords,
      dataIrs,
      dataCvm,
      dataDous,
      dataBacen,
      dataEvents,
      dataPropositions,
      dataOficialDiary,
      dataSpeeches,
      dataNotices,
      dataSocial,
      dataRegulatoryAgencies,
      bucket,
      buckets,
      activeBuckets,
      inactiveBuckets,
      selectedBucket,
      isLoading,
      pagination,
      filterVisible,
      toggleFilter,
      toggleResumeSearch,
      resumeSearch,
      setSelectedBucket,
      handleChangeBucket,
      handleAddBucket,
      handleRemoveBucket,
      handleUpdateSearch,
      handleMountBucketState,
      handleUpdateSavedSearch,
    ]
  )

  return (
    <SearchResultsContext.Provider value={store}>
      {children}
    </SearchResultsContext.Provider>
  )
}
