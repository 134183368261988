/* eslint-disable no-unused-vars */
import { isEmpty } from 'lodash'
import { LoadingButton } from '@mui/lab'
import { TabSelector } from '../TabSelector'
import { useNavigate } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'
import { EditSavedSearchContext } from '../../context'
import { StepContainer } from '../../styles'
import { useContextSelector } from 'use-context-selector'
import {
  TooltipHelper,
  Input,
  TextLink,
  ContainerScroll,
} from '@components/atoms'
import { Filter } from '@services/nomos_api/entities/saved_searches'
import { encodeKeywords } from '@utils/functions/keyword/serialize'
import { KeywordConditionsType } from '@services/nomos_api/entities/advanced_search'
import { useDrawer } from '@contexts/Drawer'

export function StepSettings() {
  const navigate = useNavigate()
  const { closeDrawer } = useDrawer()
  const form = useContextSelector(EditSavedSearchContext, (s) => s.form)
  const search = useContextSelector(EditSavedSearchContext, (s) => s.search)
  const isLoading = useContextSelector(
    EditSavedSearchContext,
    (s) => s.isLoading
  )

  const handleSubmit = useContextSelector(
    EditSavedSearchContext,
    (s) => s.handleSubmit
  )

  const clearLocalStorageFilters = () => {
    const search = localStorage.getItem('@NOMOS:defaultFilters')
    if (search) localStorage.removeItem('@NOMOS:defaultFilters')
  }

  const handleNavigateToResults = () => {
    closeDrawer()
    clearLocalStorageFilters()

    const buckets = [] as string[]
    const filters: Record<string, Filter> = {}
    const keywords: KeywordConditionsType = search.keywords
      ? search.keywords
      : { or: [search.keyword], and: [], not: [] }

    search?.searches?.forEach((item) => {
      buckets.push(item.bucket)
      if (!isEmpty(item.filter)) {
        filters[item.bucket] = item.filter
      }
    })

    navigate(
      `/search-results?keyword=${encodeKeywords(
        keywords
      )}&buckets=${buckets.join(',')}&searchId=${search?.id}&monitorId=${
        search.monitorId
      }`,
      {
        state: { filters },
      }
    )
  }

  return (
    <StepContainer>
      <TabSelector />
      <ContainerScroll auto="true">
        <Stack direction="column" spacing={2}>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Altere o Nome da pesquisa salva no campo abaixo:
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="$font-body-1" color="$color-text-primary">
              Nome da pesquisa
            </Typography>
            <TooltipHelper>
              <Stack direction="column" spacing={1}>
                <Typography variant="caption" color="$color-text-primary">
                  O nome da pesquisa é utilizado para identificar suas pesquisas
                  salvas quando as mesmas forem apresentados em resultados de
                  buscas
                </Typography>
              </Stack>
            </TooltipHelper>
          </Stack>
          <Input
            name="name"
            error={Boolean(form.errors.name)}
            helpertext={form.errors.name}
            onBlur={form.handleBlur}
            value={form.values.name}
            onChange={form.handleChange}
            sx={{ mb: '32px' }}
          />
          <Typography
            variant="$font-body-base"
            color="$color-text-secundary"
            maxWidth={380}
          >
            Para alterar palavras-chave e outras opções desta pesquisa, clique
            no botão abaixo:
          </Typography>
          <Stack direction="row" justifyContent="flex-start">
            <TextLink
              text="Editar opções avançadas de pesquisa"
              underline="hover"
              onClick={handleNavigateToResults}
            />
          </Stack>
        </Stack>
      </ContainerScroll>
      <Stack direction="row" spacing={1}>
        <LoadingButton
          size="large"
          variant="contained"
          color="primary"
          sx={{ p: '12px 0', flex: 1 }}
          onClick={handleSubmit}
          loading={isLoading}
        >
          <Typography variant="body1" color="common.white">
            Salvar
          </Typography>
        </LoadingButton>
      </Stack>
    </StepContainer>
  )
}
