import { Box, styled } from '@mui/material'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const Filters = styled(Box)`
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${(props) =>
    props.theme.palette['$color-border-secundary']};
`

export const IFrameContainer = styled(Box)`
  display: flex;
  flex: 1;
  & iframe {
    border-width: 0px;
  }
`
