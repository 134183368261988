import { useParams } from 'react-router-dom'
import { SecundaryPage } from '@components/templates'
import StakeholdersList from '@containers/StakeholdersList'

export function StakeholdersPanel() {
  const { id } = useParams()

  return (
    <SecundaryPage
      id="stakeholders-monitor-page"
      title="Stakeholders Monitorados"
      description="Veja abaixo a lista de stakeholders que estão sendo monitorados"
    >
      <StakeholdersList monitorId={id} />
    </SecundaryPage>
  )
}
