import { CircularProgress, Typography } from '@mui/material'

import {
  ValueSideBySide,
  ValueUnique,
  Values,
  ValuesContainer,
  Wrapper,
} from './Summarized.styles'

type Value = {
  count: string
  label: string
}

export type SummarizedProps = {
  title: string
  values: Value[]
  isLoading: boolean
}

function Count({ total }: { total: string }) {
  return (
    <Typography variant="$font-title-2" color="$color-action-text-secundary">
      {total}
    </Typography>
  )
}

function Label({ text }: { text: string }) {
  return (
    <Typography variant="$font-body-1" color="$color-text-secundary">
      {text}
    </Typography>
  )
}

export default function Summarized({
  title,
  values,
  isLoading,
}: SummarizedProps) {
  return (
    <Wrapper width={300} height={195} type="bordered">
      <Typography
        variant="$font-title-4"
        color="$color-text-primary"
        sx={{ marginBottom: { md: '32px' } }}
      >
        {title}
      </Typography>

      {isLoading ? (
        <ValuesContainer>
          <CircularProgress />
        </ValuesContainer>
      ) : (
        <ValuesContainer>
          {values.length > 1 ? (
            <Values>
              {values.map((v) => (
                <ValueSideBySide key={`${v.count}-${v.label}`}>
                  <Count total={v.count} />
                  <Label text={v.label} />
                </ValueSideBySide>
              ))}
            </Values>
          ) : (
            <ValueUnique>
              <Count total={values[0].count} />
              <Typography variant="$font-body-3" color="$color-text-secundary">
                {values[0].label}
              </Typography>
            </ValueUnique>
          )}
        </ValuesContainer>
      )}
    </Wrapper>
  )
}
