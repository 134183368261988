import { useState } from 'react'
import { TextLink } from '@components/atoms'
import { EmptyState } from '@components/molecules'
import { useParams, useSearchParams } from 'react-router-dom'
import { CircularProgress, Divider, Typography } from '@mui/material'
import { OrganDocCountEntity } from '@services/nomos_api/entities/organ'

import {
  useGetOrganDocuments,
  useGetOrganDocumentsCount,
} from '@services/nomos_api/resources/organs/documents'

import {
  Container,
  Header,
  HeaderCount,
  HeaderTitle,
  DocumentsAccordion,
  DocumentsSummary,
  DocumentsList,
  AccordionSummaryIcon,
  DocumentsContainer,
  DocumentsTitle,
  Document,
} from './styles'

export default function Documents() {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const initialType = searchParams.get('type')
  const [type, setType] = useState(initialType)

  const { data: documentsCount, isFetching: isFetchingDocsCount } =
    useGetOrganDocumentsCount(id)

  const { data: documents, isFetching: isFetchingDocs } = useGetOrganDocuments(
    id,
    type
  )

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <Typography color="$color-text-primary" variant="$font-title-3">
            Documentos
          </Typography>
          <Typography color="$color-text-secundary" variant="$font-body-base">
            Veja todos os documentos disponíveis nesta comissão
          </Typography>
        </HeaderTitle>

        <HeaderCount>
          <Typography color="$color-text-secundary" variant="$font-body-2">
            Total de documentos encontrados
          </Typography>

          {isFetchingDocsCount ? (
            <CircularProgress size={25} />
          ) : (
            <Typography
              color="$color-action-background-primary-default"
              variant="$font-title-display"
            >
              {documentsCount?.reduce((p: number, c: OrganDocCountEntity) => {
                return p + c.count
              }, 0)}
            </Typography>
          )}
        </HeaderCount>
      </Header>

      {!isFetchingDocsCount && (
        <DocumentsContainer>
          {documentsCount?.map((docsCount, index) => (
            <DocumentsAccordion
              data-cy={`accordion-${index}`}
              expanded={type === docsCount.name}
              onChange={(_: React.SyntheticEvent, expanded: boolean) => {
                if (expanded) {
                  setType(docsCount.name)
                } else {
                  setType(null)
                }
              }}
            >
              <DocumentsSummary expandIcon={<AccordionSummaryIcon />}>
                <DocumentsTitle>
                  <Typography
                    variant="$font-body-2"
                    color="$color-text-secundary"
                  >
                    {docsCount.name}
                  </Typography>

                  <Typography
                    variant="$font-body-2"
                    color="$color-text-primary"
                  >
                    {docsCount.count} documentos
                  </Typography>
                </DocumentsTitle>
              </DocumentsSummary>

              <DocumentsList>
                {isFetchingDocs ? (
                  <CircularProgress size={25} />
                ) : (
                  documents?.map((document, index) => (
                    <Document data-cy={`document-${index}`}>
                      <TextLink
                        href={document.file}
                        text={`${document.acronymType} ${document.summary} ${document.description}`}
                      />

                      <Divider />
                    </Document>
                  ))
                )}
              </DocumentsList>
            </DocumentsAccordion>
          ))}
        </DocumentsContainer>
      )}

      {!isFetchingDocsCount && documentsCount?.length === 0 && (
        <EmptyState text="Sem documentos" />
      )}
    </Container>
  )
}
