import { Typography } from '@mui/material'
import { OrganDetailContext } from '@pages/private/OrganDetail/context'
import { useContextSelector } from 'use-context-selector'

import { Container, Title, Elements, Element, Info } from './styles'

export default function Detail() {
  const organ = useContextSelector(OrganDetailContext, (s) => s.organ)

  return (
    <Container>
      <Title>
        <Typography variant="$font-title-4" color="$color-text-primary">
          Detalhes
        </Typography>
        <Typography variant="$font-body-2" color="$color-text-primary">
          {organ?.situation}
        </Typography>
      </Title>

      <Info>
        <Elements>
          <Element>
            <Typography variant="$font-body-2" color="$color-text-secundary">
              Tipo:
            </Typography>

            <Typography variant="$font-text-link" color="$color-text-primary">
              {organ?.type}
            </Typography>
          </Element>
        </Elements>

        <Elements>
          <Element>
            <Typography variant="$font-body-2" color="$color-text-secundary">
              Presidente:
            </Typography>
            <Typography variant="$font-text-link" color="$color-text-primary">
              {organ?.president?.name || 'Não disponível'} (
              {organ?.president?.acronymParty})
            </Typography>
          </Element>

          <Element>
            <Typography variant="$font-body-2" color="$color-text-secundary">
              Local:
            </Typography>
            <Typography variant="$font-text-link" color="$color-text-primary">
              {organ?.room || 'Não disponível'}
            </Typography>
          </Element>
        </Elements>
      </Info>
    </Container>
  )
}
