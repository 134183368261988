import { createRef, useCallback, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import {
  TooltipHelper,
  Input,
  Button,
  InputAdvancedSearch,
  ContainerScroll,
} from '@components/atoms'
import React from 'react'
import { TextError } from '../../components/TextError'
import { SaveSearchContext } from '../../context'
import { StepContainer } from '../../styles'
import { SaveSearchHeader } from '../../components/SaveSearchHeader'
import InputPickMonitor from '@components/organisms/InputPickMonitor/InputPickMonitor'
import { InputPickMonitorRef } from '@components/organisms/InputPickMonitor/InputPickMonitor.types'
import { MultiSelectChipOption } from '@components/atoms/InputMultiSelectChip/InputMultiSelectChip'

export function StepSettings() {
  const form = useContextSelector(SaveSearchContext, (s) => s.form)
  const refInputMonitor = createRef<InputPickMonitorRef>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleUpdateStep = useContextSelector(
    SaveSearchContext,
    (s) => s.handleUpdateStep
  )

  const handleValidateForm = useCallback(async (): Promise<boolean> => {
    const validations = await form.validateForm()
    const invalid = ['monitors', 'name', 'keyword', 'keywords'].some((value) =>
      Object.keys(validations).includes(value)
    )
    if (!invalid) form.setFieldError('alert', undefined)
    return !invalid
  }, [form])

  const handleNextStep = () => {
    setIsLoading(true)
    refInputMonitor.current
      ?.createPendingMonitors()
      .then(async (monitors: MultiSelectChipOption[]) => {
        form.setFieldValue('monitors', monitors)
        const isValid = await handleValidateForm()
        if (isValid) handleUpdateStep('notification')
      })
      .finally(() => setIsLoading(false))
  }

  const handlePrevStep = React.useCallback(() => {
    handleUpdateStep('introduction')
  }, [])

  return (
    <StepContainer data-testid="settings-step">
      <SaveSearchHeader onClickBack={handlePrevStep} />
      <ContainerScroll spacing={24}>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-title-5" color="$color-text-secundary">
            Geral
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Nomeie a pesquisa e selecione os painéis que deseja salvá-la. Você
            pode editar a pesquisa salva a qualquer momento acessando ela na
            sessão &quot;Pesquisas Salvas&quot;
          </Typography>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-title-5" color="$color-text-secundary">
            Nome da pesquisa
          </Typography>
          <Input
            name="name"
            id="name-input"
            onBlur={form.handleBlur}
            value={form.values.name}
            onChange={form.handleChange}
            helpertext={form.errors.name}
            error={Boolean(form.errors.name)}
            placeholder="Ex.: Minas, Energia e Combustível"
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="$font-body-1" color="$color-text-primary">
              Palavra Chave
            </Typography>
            <TooltipHelper>
              <Typography variant="caption" color="$color-text-primary">
                Palavra Chave é definida de acordo com os valores inseridos no
                campo de busca
              </Typography>
            </TooltipHelper>
          </Stack>
          <InputAdvancedSearch
            value={form.values.keywords}
            disabled
            disableTooltip
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="$font-body-1" color="$color-text-primary">
              Painel
            </Typography>
            <TooltipHelper>
              <Stack direction="column" spacing={1}>
                <Typography variant="caption" color="$color-text-primary">
                  Salve sua pesquisa em um ou mais painéis e monitore sempre que
                  algum novo dado surgir
                </Typography>
              </Stack>
            </TooltipHelper>
          </Stack>
          <InputPickMonitor
            ref={refInputMonitor}
            initialMonitors={form.values.monitors}
            onChangeOptions={(options) =>
              form.setFieldValue('monitors', options)
            }
          />
          <TextError value={form?.errors?.monitors} />
        </Stack>
      </ContainerScroll>
      <Button
        text="Próximo"
        size="large"
        variant="contained"
        color="primary"
        sx={{ p: '12px 0', mt: 3 }}
        loading={isLoading}
        onClick={handleNextStep}
      />
    </StepContainer>
  )
}
