import Events from './Events'
import Dashboard from './Dashboard'
import Stakeholders from './Stakeholders'
import { Stack, Typography } from '@mui/material'
import Notifications from './Notifications'

import { DashboardContainer, DashboardContent, DashboardCards } from './styles'

export default function Overview() {
  return (
    <DashboardContainer>
      <Stack width="88%" margin="0 auto">
        <Typography variant="$font-title-2" color="$color-text-primary">
          Visão geral
        </Typography>

        <Typography variant="$font-body-base" color="$color-text-secundary">
          Abaixo uma visão macro e resumida das suas principais atividades
          realizadas na plataforma e as <br />
          principais descobertas da semana de acordo com as suas pesquisas
          salvas.
        </Typography>

        <DashboardContent container spacing={3}>
          <DashboardCards item xs={12} md={7}>
            <Dashboard />
          </DashboardCards>

          <DashboardCards item xs={12} md={5}>
            <Events />
          </DashboardCards>

          <DashboardCards item xs={12} md={7}>
            <Stakeholders />
          </DashboardCards>

          <DashboardCards item xs={12} md={5}>
            <Notifications />
          </DashboardCards>
        </DashboardContent>
      </Stack>
    </DashboardContainer>
  )
}
