/* eslint-disable @typescript-eslint/no-explicit-any */
import { SyntheticEvent, useMemo } from 'react'
import { PaperDropdownStyle } from '@theme/styles'
import { RenderTags } from './components/render-tags'
import { RenderInput } from './components/render-input'
import { RenderGroup } from './components/render-group'
import { RenderOption } from './components/render-option'
import {
  FormControl,
  Autocomplete,
  SxProps,
  createFilterOptions,
  Stack,
  CircularProgress,
  Typography,
} from '@mui/material'

export type MultiSelectChipOption = {
  key: string
  label: string
  group?: string
}

const defaultProps = {
  sx: {},
}

export type InputMultiSelectChipProps = {
  id: string
  sx?: SxProps
  value: MultiSelectChipOption[]
  disabled: boolean
  label: string
  options: MultiSelectChipOption[]
  loading: boolean
  loadingLabel?: string
  groupAllSelectedOptions?: string | undefined
  maxRenderTags?: number
  textSearchValue?: string
  enableNewValueLabel?: string
  onFocus?: () => void
  onChangeTextSearch?: (text: string) => void
  onChangeOptions: (event: SyntheticEvent<Element, Event>, value: any) => void
}

const filter = createFilterOptions<MultiSelectChipOption>()

function InputMultiSelectChipComponent(props: InputMultiSelectChipProps) {
  const {
    id,
    sx,
    value,
    disabled,
    label,
    options,
    loading,
    loadingLabel,
    textSearchValue,
    maxRenderTags = 3,
    groupAllSelectedOptions,
    enableNewValueLabel,
    onFocus,
    onChangeOptions,
    onChangeTextSearch,
  } = props

  const groupedOptions = useMemo(() => {
    return options
      .map((option) => ({
        ...option,
        group: value.some((i) => i.key === option.key)
          ? 'Selecionados'
          : 'Outros',
      }))
      .sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.group === 'Selecionados' ? -1 : b.group === 'Selecionados' ? 1 : 0
      )
  }, [options, value])

  return (
    <FormControl>
      <Autocomplete
        sx={sx}
        value={value}
        loading={loading}
        disableCloseOnSelect
        loadingText={
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={12} />
            <Typography variant="$font-body-base" color="$color-text-secundary">
              {loadingLabel || 'Carregando...'}
            </Typography>
          </Stack>
        }
        disabled={disabled}
        multiple
        options={groupedOptions}
        onChange={onChangeOptions}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        id={id || 'input-multiselect-chip'}
        data-testid={id || 'input-multiselect-chip'}
        groupBy={(option) => String(option.group)}
        renderInput={(params) => (
          <RenderInput
            params={params}
            label={label}
            onChangeTextSearch={onChangeTextSearch}
          />
        )}
        renderOption={(props, option, state) => (
          <RenderOption
            props={props}
            option={option}
            state={state}
            groupNewOptionLabel={enableNewValueLabel}
          />
        )}
        renderGroup={({ group, children }) => (
          <RenderGroup
            group={group}
            fnRemoveAll={() =>
              onChangeOptions(
                undefined as unknown as SyntheticEvent<Element, Event>,
                []
              )
            }
            fnSelectAll={() => {
              onChangeOptions(
                undefined as unknown as SyntheticEvent<Element, Event>,
                options
              )
            }}
          >
            {children}
          </RenderGroup>
        )}
        renderTags={(values, getTagProps) => (
          <RenderTags
            values={values}
            options={groupedOptions}
            getTagProps={getTagProps}
            maxRenderTags={maxRenderTags}
            onChangeOptions={onChangeOptions}
            groupAllSelectedOptions={groupAllSelectedOptions}
          />
        )}
        ChipProps={{ style: { maxHeight: 350 } }}
        componentsProps={{ paper: PaperDropdownStyle }}
        onFocus={() => onFocus?.()}
        onKeyDown={(e) => {
          if (e.key.toLowerCase() === 'enter') {
            onChangeOptions(e, [
              ...value,
              { key: undefined, label: textSearchValue },
            ])
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          const { inputValue } = params
          if (enableNewValueLabel) {
            if (inputValue.length > 0) {
              filtered.push({
                key: inputValue,
                label: inputValue,
                group: enableNewValueLabel,
              })
            }
          }
          return filtered
        }}
      />
    </FormControl>
  )
}

InputMultiSelectChipComponent.defaultProps = defaultProps

export default InputMultiSelectChipComponent
