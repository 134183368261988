/* eslint-disable react/no-array-index-key */
import { useMemo } from 'react'
import ptBR from 'date-fns/locale/pt-BR'
import { useModal } from '@contexts/Modal'
import { format, parseISO } from 'date-fns'
import EventThemeModal from '../EventThemeModal'
import { ExpandMore } from '@mui/icons-material'
import IconNoData from '@assets/icons/nodata.svg'
import EventGuestsModal from '../EventGuestsModal'
import { EventDetailsContext } from '../../context'
import { PropositionItem } from '../PropositionItem'
import { useContextSelector } from 'use-context-selector'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Shimmer, ShimmerText, Button } from '@components/atoms'
import { formatTime } from '@utils/functions/formatter/formatter'
import { Divider, Stack, Tooltip, Typography } from '@mui/material'
import { HouseInfo } from '@components/molecules/HouseInfo/HouseInfo.stories'

import {
  Accordion,
  AccordionHeader,
  Container,
  EmptyData,
  ScrollGuests,
} from './styles'

const NUMBER_OF_GUESTS_SHOWN_INITIALLY = 13
const LIMIT_NUMBER_OF_WORDS = 100

function Content() {
  const event = useContextSelector(EventDetailsContext, (s) => s.event)
  const isLoading = useContextSelector(EventDetailsContext, (s) => s.isLoading)
  const { openModal } = useModal()

  const formatText = (text: string | undefined, isLimitedText?: boolean) => {
    if (!text || text === 'undefined') return '--'
    if (isLimitedText) {
      const unifiedText = text.replace(/^\s+|\s+$/gm, ' ')
      const truncatedText = unifiedText.substring(0, LIMIT_NUMBER_OF_WORDS)

      return truncatedText
    }
    return text
  }

  const appreciatedPropositions = event?.schedule
    ? event.schedule.filter((item) => item?.appreciated)
    : []

  const unappreciatedPropositions = event?.schedule
    ? event.schedule.filter((item) => !item?.appreciated)
    : []

  const mapperGuests = useMemo(() => {
    const slicedParliamentarians = (event?.parliamentarians || [])?.slice(
      0,
      NUMBER_OF_GUESTS_SHOWN_INITIALLY
    )
    const mapperResult = event?.parliamentarians
      ?.map((item, index) => (
        <Stack direction="row" key={item.stakeholderId} alignItems="center">
          <Typography
            variant="$font-title-5"
            color="$color-text-primary"
            lineHeight={2}
          >
            {[item.name, item.acronymParty].filter((i) => !!i).join(' - ')}
          </Typography>
          {index !== slicedParliamentarians.length - 1 && (
            <Typography
              mx={2}
              lineHeight={2}
              variant="$font-title-5"
              color="$color-text-primary"
            >
              /
            </Typography>
          )}
        </Stack>
      ))
      ?.slice(0, NUMBER_OF_GUESTS_SHOWN_INITIALLY)
    return mapperResult || []
  }, [event])

  return (
    <Container direction="column" spacing={3}>
      <HouseInfo text={event?.openDataResource || 'N/A'} />

      <Typography variant="$font-title-3" color="muted.dark">
        Informações gerais
      </Typography>

      <Stack
        direction="row"
        gap={3}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack direction="column" spacing={1} width="calc(100%/2.5)">
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Tema
          </Typography>
          <Stack direction="row" alignItems="flex-end" position="relative">
            <ShimmerText isLoading={isLoading}>
              <Typography variant="$font-title-5" color="$color-text-primary">
                {formatText(event?.description, true)}
                &nbsp; &nbsp; &nbsp;
                <Button
                  size="medium"
                  variant="text"
                  sx={{ marginLeft: '-8px' }}
                  onClick={() =>
                    openModal(
                      <EventThemeModal
                        themeDescription={formatText(event?.description)}
                      />,
                      { lockscroll: true }
                    )
                  }
                  text="Ver descrição completa"
                />
              </Typography>
            </ShimmerText>
          </Stack>
        </Stack>

        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Data e Hora
          </Typography>
          <Shimmer isLoading={isLoading}>
            <Typography variant="$font-title-5" color="$color-text-primary">
              {event?.dateStart &&
                `${format(parseISO(event?.dateStart), 'EEEE - dd/MM/yyyy', {
                  locale: ptBR,
                })} às ${formatTime(event?.dateStart, `HH:mm`)}`}
            </Typography>
          </Shimmer>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Local
          </Typography>
          <ShimmerText isLoading={isLoading} words={3}>
            <Tooltip title={formatText(event?.local)} placement="top">
              <Typography variant="$font-title-5" color="$color-text-primary">
                {formatText(event?.local)}
              </Typography>
            </Tooltip>
          </ShimmerText>
        </Stack>

        <Stack direction="column" spacing={1}>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Situação
          </Typography>
          <Shimmer isLoading={isLoading}>
            <Typography variant="$font-title-5" color="$color-text-primary">
              {formatText(event?.situation)}
            </Typography>
          </Shimmer>
        </Stack>
      </Stack>

      <Stack direction="column" spacing={1}>
        <Typography variant="$font-body-base" color="$color-text-secundary">
          Convidados
        </Typography>
        <ScrollGuests>
          <Stack direction="column" spacing={1}>
            <ShimmerText isLoading={isLoading} words={6} height={16} />
            <ShimmerText isLoading={isLoading} words={6} height={16} />
            <ShimmerText isLoading={isLoading} words={6} height={16} />
            <ShimmerText isLoading={isLoading} words={6} height={16} />
            <ShimmerText isLoading={isLoading} words={6} height={16}>
              {event?.parliamentarians && event?.parliamentarians.length > 0 ? (
                <Stack direction="row" flexWrap="wrap">
                  {mapperGuests}
                  <Stack alignItems="flex-end" mx={2} justifyContent="center">
                    <Button
                      variant="text"
                      text={`Ver todos os ${event?.parliamentarians?.length} convidados`}
                      onClick={() =>
                        openModal(
                          <EventGuestsModal
                            listGuests={event?.parliamentarians}
                            numberGuests={event?.parliamentarians?.length}
                          />,
                          { lockscroll: true }
                        )
                      }
                    />
                  </Stack>
                </Stack>
              ) : (
                <Typography variant="$font-title-5" color="$color-text-primary">
                  Não há convidados
                </Typography>
              )}
            </ShimmerText>
          </Stack>
        </ScrollGuests>
      </Stack>

      <Divider />

      <Typography variant="$font-title-3" color="$color-text-primary">
        Proposições em Pauta
      </Typography>

      <Stack direction="column" spacing={1}>
        <Shimmer isLoading={isLoading} height={60} width="100%">
          <Accordion>
            <AccordionHeader expandIcon={<ExpandMore />}>
              <Typography variant="$font-title-4" color="$color-text-primary">
                Apreciadas ({appreciatedPropositions.length})
              </Typography>
            </AccordionHeader>

            {appreciatedPropositions.length > 0 ? (
              <Divider sx={{ m: 0 }} />
            ) : (
              <EmptyData>
                <img src={IconNoData} alt="No Data" />
                <span>Não há proposições encontradas</span>
              </EmptyData>
            )}

            <AccordionDetails>
              {appreciatedPropositions.map((item, index) => (
                <PropositionItem item={item} key={index} showStatus />
              ))}
            </AccordionDetails>
          </Accordion>
        </Shimmer>

        <Shimmer isLoading={isLoading} height={60} width="100%">
          <Accordion>
            <AccordionHeader expandIcon={<ExpandMore />}>
              <Typography variant="$font-title-4" color="$color-text-primary">
                Não Apreciadas ({unappreciatedPropositions.length})
              </Typography>
            </AccordionHeader>

            {unappreciatedPropositions.length > 0 ? (
              <Divider sx={{ m: 0 }} />
            ) : (
              <EmptyData>
                <img src={IconNoData} alt="No Data" />
                <span>Não há proposições encontradas</span>
              </EmptyData>
            )}

            <AccordionDetails>
              {unappreciatedPropositions.map((item, index) => (
                <PropositionItem item={item} key={index} showStatus={false} />
              ))}
            </AccordionDetails>
          </Accordion>
        </Shimmer>
      </Stack>
    </Container>
  )
}

export default Content
