import Tag from '@components/atoms/Tag/Tag'
import { Stack, Typography } from '@mui/material'
import { useContextSelector } from 'use-context-selector'
import { TagManagerContext } from '@containers/TagManager/context'
import { InlineTagsContainer } from '@containers/TagManager/styles'

export function TagManagerListSelecteds(): JSX.Element {
  const handleTagSelection = useContextSelector(
    TagManagerContext,
    (s) => s.handleTagSelection
  )

  const selecteds = useContextSelector(TagManagerContext, (s) => s.selecteds)

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="$font-body-base" color="$color-text-secundary">
        {!selecteds || selecteds.length === 0
          ? 'Não há tags relacionadas a esta proposição'
          : 'Tags relacionadas a esta proposição'}
      </Typography>
      {selecteds?.length > 0 && (
        <InlineTagsContainer>
          {selecteds.map((tag) => (
            <Tag
              {...tag}
              size="small"
              key={`item-tag-${tag.id}`}
              onRemove={() => handleTagSelection(tag, false)}
            />
          ))}
        </InlineTagsContainer>
      )}
    </Stack>
  )
}
