import { useMemo, useState } from 'react'
import { MonitoringContext } from './context'
import {
  MonitoringControllerProps,
  MonitoringTab,
  MonitoringTabKeys,
} from './types'

function MonitoringController({
  id,
  house,
  origin,
  isSaved,
  children,
  onFinish,
  onRemove,
}: MonitoringControllerProps) {
  // define states
  const [selectedTab, setSelectedTab] = useState<MonitoringTabKeys>(
    MonitoringTab.monitor
  )

  // expose shared states
  const store = useMemo(
    () => ({
      id,
      house,
      origin,
      isSaved,
      selectedTab,
      onFinish,
      onRemove,
      setSelectedTab,
    }),
    [
      id,
      origin,
      house,
      isSaved,
      selectedTab,
      onFinish,
      onRemove,
      setSelectedTab,
    ]
  )

  return (
    <MonitoringContext.Provider value={store}>
      {children}
    </MonitoringContext.Provider>
  )
}

MonitoringController.defaultProps = {
  onFinish: null,
}

export default MonitoringController
