import { useDrawer } from '@contexts/Drawer'
import { ContainerScroll } from '@components/atoms'
import { BlockedFeatureDrawerProps } from './types'
import { IconButton, Stack, Typography } from '@mui/material'
import { BlockedFeatureSelectorMessage } from './components/selector'
import {
  Container,
  ContentBody,
  HeaderTitle,
  Header,
  IconClose,
} from './styles'

export function BlockedFeatureView({
  featureTitle = 'Requer permissão',
  featureSubtitle = 'Não é possível realizar essa operação',
  featureDescription,
}: BlockedFeatureDrawerProps) {
  const { closeDrawer } = useDrawer()

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <Typography variant="$font-title-3" color="$color-text-primary">
            {featureTitle}
          </Typography>
          <IconButton data-testid="close-save-search-btn" onClick={closeDrawer}>
            <IconClose />
          </IconButton>
        </HeaderTitle>
      </Header>
      <ContentBody>
        <ContainerScroll>
          <Stack direction="column" spacing={3}>
            <Stack direction="column" spacing={1}>
              <Typography variant="$font-title-5" color="$color-text-secundary">
                {featureSubtitle}
              </Typography>
              {featureDescription && (
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  {featureDescription}
                </Typography>
              )}
            </Stack>
            <BlockedFeatureSelectorMessage />
          </Stack>
        </ContainerScroll>
      </ContentBody>
    </Container>
  )
}
