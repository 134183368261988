import _ from 'lodash'
import { parseISO } from 'date-fns'
import { theme } from '@theme/index'
import { Chip, Stack, Typography } from '@mui/material'
import { HouseInfo } from '@components/molecules'
import { EventEntity } from '@services/nomos_api/entities/event'
import { capitalize } from '@utils/functions/capitalize/capitalize'
import { OpenDataResourceEnum } from '@enums/OpenDataResourceEnum'
import { CalendarBlank, Clock, MapPin } from '@phosphor-icons/react'
import {
  formatDate,
  formatTime,
  formatterHighlight,
} from '@utils/functions/formatter/formatter'

import {
  CardContainer,
  CardContent,
  CardRow,
  ContentIconText,
  TextTitle,
  TextLabel,
  TextValue,
  TextChip,
  HighlightedSchedule,
} from './Event.styles'
import { useMemo } from 'react'
import { Indicator } from '@components/molecules/Status/Status.styles'
import colors from '@theme/colors'
import { Button, TextWrap } from '@components/atoms'
import { useModal } from '@contexts/Modal'
import { EventScheduleMatch } from '@containers/Modals/EventScheduleMatch'

export type CardEventProps = {
  event: Partial<EventEntity>
  handleOpenModalDetailsEvent: (event: Partial<EventEntity>) => void
}

function CardEvent(props: CardEventProps) {
  const { openModal } = useModal()
  const { event, handleOpenModalDetailsEvent } = props

  const date = formatDate(event.dateStart)

  const weekday = !event.dateStart
    ? '--'
    : parseISO(event.dateStart).toLocaleDateString('pt-BR', {
        weekday: 'long',
      })

  const hour = formatTime(event.dateStart)

  const { savedPropositions } = event
  const countSavedPropositions = useMemo((): number => {
    return (savedPropositions || []).reduce<Map<string, number>>(
      (counter, item) => {
        counter.set(
          item.propositionId,
          (counter.get(item.propositionId) || 0) + 1
        )
        return counter
      },
      new Map<string, number>()
    ).size
  }, [savedPropositions])

  const description = useMemo(() => {
    return formatterHighlight(event, ['description'], 'Não Informado')
  }, [])

  const hasFoundedInSchedule = useMemo(() => {
    return (event.highlights || []).some((i) => i.path.startsWith('schedule'))
  }, [])

  return (
    <CardContainer
      borderColor={event.borderColor}
      onClick={() => handleOpenModalDetailsEvent(event)}
    >
      <CardContent>
        <CardRow>
          <TextTitle>
            {[
              event?.organs?.[0]?.name,
              event?.type ||
                _.get(
                  OpenDataResourceEnum,
                  event.openDataResource || '',
                  'Não informado'
                ),
            ]
              .filter((i) => !!i)
              .join(' - ')}
          </TextTitle>
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            sx={{ px: 1, py: 1.8, display: 'flex', alignItems: 'center' }}
            label={<TextChip>{event.situation || 'N/D'}</TextChip>}
          />
        </CardRow>

        {countSavedPropositions > 0 ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Indicator color={colors['$color-semantic-success']} />
            <Typography variant="$font-body-base" color="$color-text-primary">
              {countSavedPropositions}
              {countSavedPropositions === 1 ? 'proposição' : 'proposições'}
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              que você monitora{' '}
              {countSavedPropositions === 1 ? 'está' : 'estão'} na pauta
            </Typography>
          </Stack>
        ) : (
          <Typography variant="$font-body-2" color="#556282">
            Não há proposições deste painel em pauta
          </Typography>
        )}

        <CardRow>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextLabel>Tipo de evento:</TextLabel>
            <TextValue>{event.type || 'N/A'}</TextValue>
          </Stack>
        </CardRow>

        <CardRow>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextWrap lines={2}>
              <Typography variant="$font-body-2" color="$color-text-secundary">
                <TextLabel>Tema:</TextLabel>
                <Typography
                  variant="$font-body-base"
                  color="$color-text-primary"
                >
                  {description}
                </Typography>
              </Typography>
            </TextWrap>
          </Stack>
        </CardRow>

        <CardRow>
          <HouseInfo text={event.openDataResource || 'N/A'} />

          <ContentIconText>
            <MapPin size={18} color={theme.palette['$color-text-primary']} />
            <TextValue>{event.local?.name || 'Não Informado'}</TextValue>
          </ContentIconText>

          <ContentIconText>
            <CalendarBlank
              size={18}
              color={theme.palette['$color-text-primary']}
            />
            <TextValue>
              {capitalize(weekday)} - {date}
            </TextValue>
          </ContentIconText>

          <ContentIconText>
            <Clock size={18} color={theme.palette['$color-text-primary']} />
            <TextValue>{hour}</TextValue>
          </ContentIconText>
        </CardRow>

        {hasFoundedInSchedule && (
          <CardRow>
            <HighlightedSchedule>
              <Typography variant="$font-body-base" color="$color-text-primary">
                Palavra-chave encontrada nos itens da pauta.{' '}
                <Button
                  size="tiny"
                  variant="text"
                  text="visualizar"
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  onClick={(e) => {
                    e.stopPropagation()
                    openModal(<EventScheduleMatch event={event} />, {
                      lockscroll: true,
                      scroll: 'paper',
                    })
                  }}
                />
              </Typography>
            </HighlightedSchedule>
          </CardRow>
        )}
      </CardContent>
    </CardContainer>
  )
}

export default CardEvent
