import produce from 'immer'
import { useCallback, useMemo, useState } from 'react'
import { CardTemplate } from '../CardTemplate'
import { BucketsEnum } from '@utils/buckets/type'
import { Chip, Stack, Typography } from '@mui/material'
import { CvmEntity } from '@services/nomos_api/entities/cvm'
import { SavedBucketEntity } from '@entities/SavedBucketEntity'
import { useSearchResultsUrl } from '@hooks/useSearchResultsUrl'
import {
  formatDate,
  formatterHighlight,
} from '@utils/functions/formatter/formatter'
import { Button, SaveBucketButton, TextWrap } from '@components/atoms'

type Props = { cvm: CvmEntity }

export function CardCvm({ cvm: cvmState }: Props) {
  const [cvm, setCvm] = useState<CvmEntity>(cvmState)
  const { url } = useSearchResultsUrl(`/cvm/${cvm.id}`)

  const handleAddSavedBucket = useCallback(
    (savedbuckets: SavedBucketEntity[]) => {
      setCvm(
        produce(cvm, (draft) => {
          draft.savedBuckets.push(...savedbuckets)
        })
      )
    },
    [setCvm, cvm]
  )

  const handleRemoveSavedBucket = useCallback(
    (removedIds: string[]) => {
      setCvm({
        ...cvm,
        savedBuckets: cvm.savedBuckets.filter(
          (sb) => !removedIds.includes(sb.id)
        ),
      })
    },
    [setCvm, cvm]
  )

  const title = useMemo(() => {
    return formatterHighlight(cvm, ['title'], 'Não Informado')
  }, [])

  const subject = useMemo(() => {
    return formatterHighlight(cvm, ['subject'], 'Não Informado')
  }, [])

  return (
    <CardTemplate
      content={[
        <>
          <Stack direction="row" spacing={1} alignItems="center">
            <Chip
              size="small"
              color="primary"
              variant="outlined"
              sx={{ paddingX: 1 }}
              label={
                <Typography
                  variant="$font-body-3"
                  color="$color-action-text-secundary"
                >
                  {cvm.type || 'Não Informado'}
                </Typography>
              }
            />
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="$color-text-primary" variant="$font-body-1">
              {title}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <TextWrap lines={2}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secundary"
              >
                {subject}
              </Typography>
            </TextWrap>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="$color-text-secundary" variant="$font-body-2">
              Data de publicação:
            </Typography>
            <Typography color="$color-text-primary" variant="$font-body-2">
              {formatDate(cvm.date) || 'Não Informado'}
            </Typography>
          </Stack>
        </>,
      ]}
      actions={[
        <SaveBucketButton
          id={cvm?.id}
          bucket={BucketsEnum.cvm}
          isSaved={cvm?.savedBuckets?.length > 0}
          openDataResource="cvm"
          onCreatedSavedBucket={handleAddSavedBucket}
          onRemovedSavedBucket={handleRemoveSavedBucket}
        />,
        <Button
          text={<Typography variant="$font-body-2">Detalhes</Typography>}
          size="medium"
          icon="arrow"
          sx={{ height: '34px', m: 0 }}
          href={url}
          target="_blank"
        />,
      ]}
    />
  )
}
