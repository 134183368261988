/* eslint-disable no-inline-styles/no-inline-styles */
import { useEffect, useMemo, useState } from 'react'
import { MultiSelect } from '@components/atoms'
import { CircularProgress, Stack } from '@mui/material'
import { useGetAlltMonitors } from '@services/nomos_api/resources/monitor/list'

import { Container, Filters, IFrameContainer } from './styles'
import { theme } from '@theme/index'

export default function Insights() {
  const { data, isFetching } = useGetAlltMonitors({})
  const [monitors, setMonitors] = useState<string[]>([])

  const handleChangeOptions = (options: string[]) => {
    setMonitors(options?.map((o) => `ObjectId("${o}")`) || [])
  }

  const filter = useMemo(() => {
    return `{"monitorId":{"$in":[${monitors.join(',')}]}}`
  }, [monitors])

  useEffect(() => {
    if (data) {
      handleChangeOptions(data.map((m) => m.id))
    }
  }, [data])

  if (isFetching) {
    return (
      <CircularProgress
        data-cy="insights"
        sx={{ marginLeft: '50%', marginTop: '20%' }}
      />
    )
  }

  return (
    <Container data-cy="insights">
      <Filters>
        <Stack width="88%" margin="0 auto" justifyContent="center">
          <MultiSelect
            size="small"
            id="monitor-select"
            name="monitors"
            label="Monitores"
            options={
              data?.map((m) => ({
                label: m.name,
                value: m.id,
              })) || []
            }
            onChangeOptions={handleChangeOptions}
          />
        </Stack>
      </Filters>

      <IFrameContainer>
        <iframe
          width="100%"
          height="100%"
          title="Nomos Insights"
          style={{
            background: 'white',
            paddingTop: '10px',
            margin: '0 6%',
            borderLeft: `1px solid ${theme.palette['$color-border-secundary']}`,
            borderRight: `1px solid ${theme.palette['$color-border-secundary']}`,
          }}
          src={`https://charts.mongodb.com/charts-nomos-oxgic/embed/dashboards?id=02fe4a4d-9a45-43e7-8ccc-2ebada2d3977&theme=light&autoRefresh=true&maxDataAge=86400&showTitleAndDesc=false&scalingWidth=scale&scalingHeight=fixed&attribution=false&filter=${filter}`}
        />
      </IFrameContainer>
    </Container>
  )
}
