import moment from 'moment'
import { useEffect, useState } from 'react'
import { DateRangePicker, Input } from '@components/atoms'
import { Box, Grid, Stack, Typography } from '@mui/material'
import {
  ReportMonitorCategoryType,
  ReportMonitorCategoryPeriodType,
} from '../../types'

type Props = {
  name: string
  title: string
  description: string
  enabledPeriod?: boolean
  onChange: (value: ReportMonitorCategoryType) => void
}

export function ReportCategorySetting({
  name,
  title,
  onChange,
  description,
  enabledPeriod = false,
}: Props): JSX.Element {
  const [enabled, setEnabled] = useState<boolean>(false)
  const [period, setPeriod] = useState<
    ReportMonitorCategoryPeriodType | undefined
  >(undefined)

  useEffect(() => {
    if (enabled) onChange({ enabled: true, period })
    else onChange({ enabled: false, period: undefined })
    if (!enabled) setPeriod(undefined)
  }, [enabled, period])

  return (
    <Box>
      <Grid container spacing={5} py={1}>
        <Grid item xs={6}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Box pt={0.5}>
              <Input
                type="checkbox"
                name="report-category"
                checked={enabled}
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEnabled(e.target.checked as unknown as boolean)
                }
              />
            </Box>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="$font-body-base"
                color="$colot-text-primary"
                lineHeight={1.1}
              >
                {title}
              </Typography>
              <Typography variant="$font-body-3" color="$color-text-secundary">
                {description}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          {enabledPeriod && (
            <DateRangePicker
              size="tiny"
              label={enabled ? 'Período' : 'Desabilitado'}
              disabled={!enabled}
              startDate={period?.from}
              endDate={period?.to}
              onChange={(from, to) => {
                if (!from || !to) {
                  setPeriod(undefined)
                } else {
                  setPeriod({
                    from: moment(from).format('YYYY-MM-DD'),
                    to: moment(to).format('YYYY-MM-DD'),
                  })
                }
              }}
              options={[
                { label: 'Última semana', days: -7 },
                { label: 'Próxima semana', days: 7 },
              ]}
              axisDirection="right"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
