import { Theme } from '@mui/system'
import { theme } from '@theme/index'
import { BoxWrapper, Indicator } from './Status.styles'
import { Variant } from '@mui/material/styles/createTypography'
import { SxProps, Typography, TypographyVariantsOptions } from '@mui/material'

const defaultProps = {
  sx: {},
}

type StatusComponentProps = {
  text: string | undefined
  variant?: keyof TypographyVariantsOptions
  color: string | undefined
  sx?: SxProps<Theme>
}

function StatusComponent({
  text,
  color,
  sx,
  variant = '$font-body-base',
}: StatusComponentProps) {
  return (
    <BoxWrapper data-testid="status-component" sx={sx}>
      <Indicator color={color || theme.palette.muted.main} />
      <Typography
        variant={variant as unknown as Variant}
        color="$color-text-secundary"
      >
        {text || '--'}
      </Typography>
    </BoxWrapper>
  )
}

StatusComponent.defaultProps = defaultProps

export default StatusComponent
