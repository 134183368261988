import produce from 'immer'
import { useDrawer } from '@contexts/Drawer'
import { useCallback, useState } from 'react'
import { BucketsEnum } from '@utils/buckets/type'
import { Avatar, Button } from '@components/atoms'
import { MonitoringDrawer } from '@containers/SharedDrawers'
import { useSearchResultsUrl } from '@hooks/useSearchResultsUrl'
import { PushPinOutlined, PushPinRounded } from '@mui/icons-material'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'
import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import {
  SavedStakeholderEntity,
  StakeholderEntity,
} from '@services/nomos_api/entities/stakeholder'

import {
  CardContainer,
  CardContent,
  CardElement,
  CardInfo,
  CardPin,
  CardOptions,
} from './styles'

export type StakeholderProps = {
  stakeholder: StakeholderEntity
}

export function CardStakeholder({
  stakeholder: stakeholderState,
}: StakeholderProps) {
  const [stakeholder, setStakeholder] = useState(stakeholderState)
  const { url } = useSearchResultsUrl(`/stakeholders/${stakeholder.id}`)

  const { openDrawer } = useDrawer()

  const handleAddSavedStakeholder = useCallback(
    (savedStakeholders: SavedStakeholderEntity[]) => {
      setStakeholder(
        produce(stakeholder, (draft) => {
          draft.savedStakeholders.push(...savedStakeholders)
        })
      )
    },
    [setStakeholder, stakeholder]
  )

  const handleRemoveSavedStakeholder = useCallback(
    (removedIds: string[]) => {
      setStakeholder({
        ...stakeholder,
        savedStakeholders: stakeholder.savedStakeholders.filter(
          (savedStakeholder) =>
            !removedIds.includes(savedStakeholder._id || savedStakeholder.id)
        ),
      })
    },
    [setStakeholder, stakeholder]
  )

  const handleMonitor = (stakeholder: StakeholderEntity) =>
    openDrawer(
      <MonitoringDrawer
        id={stakeholder.id}
        origin={BucketsEnum.stakeholders}
        onFinish={handleAddSavedStakeholder}
        onRemove={handleRemoveSavedStakeholder}
        isSaved={stakeholder.savedStakeholders?.length > 0}
      />
    )

  return (
    <CardContainer
      key={stakeholder.id}
      data-testid="stakeholder-card"
      type="bordored"
    >
      <CardInfo>
        <Stack direction="row" gap={2} flexWrap="wrap" alignItems="center">
          <Avatar
            alt={stakeholder.name}
            src={stakeholder.photoUrl}
            width={56}
            height={56}
          />
          <Stack display={{ md: 'none' }}>
            {stakeholder.roles ? (
              stakeholder.roles?.map((role) => (
                <Chip
                  key={role}
                  size="small"
                  color="primary"
                  variant="outlined"
                  data-testid="stakeholder-situation"
                  label={role || 'Indefinido'}
                />
              ))
            ) : (
              <Chip
                size="small"
                color="primary"
                variant="outlined"
                data-testid="stakeholder-situation"
                label={stakeholder.situation || 'Indefinido'}
              />
            )}
          </Stack>
        </Stack>
        <CardContent>
          <Stack display={{ xs: 'none', md: 'flex' }}>
            {stakeholder.roles ? (
              stakeholder.roles?.map((role) => (
                <Chip
                  key={role}
                  size="small"
                  color="primary"
                  variant="outlined"
                  data-testid="stakeholder-situation"
                  label={role || 'Indefinido'}
                />
              ))
            ) : (
              <Chip
                size="small"
                color="primary"
                variant="outlined"
                data-testid="stakeholder-situation"
                label={stakeholder.situation || 'Indefinido'}
              />
            )}
          </Stack>
          <CardElement>
            <Typography
              variant="$font-title-5"
              color="$color-text-primary"
              data-testid="stakeholder-name"
            >
              {capitalizeAllWords(stakeholder.name)}
            </Typography>

            <Typography
              variant="$font-body-base"
              color="primary.main"
              data-testid="stakeholder-type"
            >
              {stakeholder.title ||
                (stakeholder.openDataResource === 'camara'
                  ? 'Deputado Federal'
                  : 'Senador')}
            </Typography>
          </CardElement>

          <CardElement>
            <Typography
              variant="$font-body-base"
              color="$color-text-primary"
              data-testid="stakeholder-acronym-party"
            >
              <Typography variant="$font-body-2" color="$color-text-secundary">
                Partido:
              </Typography>
              {stakeholder.acronymParty}
            </Typography>

            <Typography variant="$font-body-base" data-testid="stakeholder-uf">
              <Typography variant="$font-body-2" color="$color-text-secundary">
                UF:
              </Typography>
              {stakeholder.electoralUF}
            </Typography>
          </CardElement>
        </CardContent>
      </CardInfo>

      <CardOptions>
        <CardPin>
          <Tooltip arrow title="Favoritar stakeholder" placement="top-end">
            <IconButton onClick={() => handleMonitor(stakeholder)}>
              {stakeholder.savedStakeholders?.length ? (
                <PushPinRounded sx={{ color: '#0C2256' }} />
              ) : (
                <PushPinOutlined sx={{ color: '#0C2256' }} />
              )}
            </IconButton>
          </Tooltip>
        </CardPin>

        <Button
          text="Detalhes"
          size="small"
          icon="arrow"
          sx={{ height: '34px', width: { xs: '100%', md: '120px' } }}
          data-cy={`button-details-stakeholder-${stakeholder.id}`}
          href={url}
          target="_blank"
        />
      </CardOptions>
    </CardContainer>
  )
}
