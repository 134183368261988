import { NoDataIcon } from '@components/icons'
import { Pagination } from '@components/molecules'
import { StakeholdersContext } from '../../context'
import { CenterContent, CardsContainer } from './styles'
import { useContextSelector } from 'use-context-selector'
import { CircularProgress, Typography } from '@mui/material'
import { pageview } from '@utils/functions/pageview/pageview'
import { CardStakeholder } from '@components/organisms/Cards'

export default function StakeholdersList() {
  const setPage = useContextSelector(StakeholdersContext, (s) => s.setPage)
  const setLimit = useContextSelector(StakeholdersContext, (s) => s.setLimit)
  const isLoading = useContextSelector(StakeholdersContext, (s) => s.isLoading)
  const stakeholders = useContextSelector(
    StakeholdersContext,
    (s) => s.stakeholders
  )
  return (
    <>
      <CardsContainer data-cy="stakeholders-list">
        <Typography variant="$font-body-base" color="$color-text-secundary">
          Exibindo {pageview(stakeholders?.pagination || {})} resultados
        </Typography>
        {stakeholders?.results?.map((stakeholder) => {
          return (
            <CardStakeholder key={stakeholder.id} stakeholder={stakeholder} />
          )
        })}
        <Pagination
          pagination={stakeholders?.pagination}
          handleUpdatePage={setPage}
          handleUpdateLimit={setLimit}
        />
      </CardsContainer>
      <CenterContent>
        {isLoading && (
          <CircularProgress data-testid="loading" color="primary" size={15} />
        )}
      </CenterContent>
      <CenterContent>
        {!isLoading && stakeholders?.results?.length === 0 && (
          <NoDataIcon
            height={36}
            text="Não foram encontrados stakeholders para a busca realizada"
          />
        )}
      </CenterContent>
    </>
  )
}
