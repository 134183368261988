import { Button, Shimmer } from '@components/atoms'
import { UserDetailContext } from './context'
import { useNavigate } from 'react-router-dom'
import { CrumbedPage } from '@components/templates'
import { useContextSelector } from 'use-context-selector'

import { Container, Info, About, Monitors } from './styles'
import { Chip, Typography } from '@mui/material'
import { NomosProductCode, NomosProductRoleName } from '@enums/NomosProductEnum'

export function UserDetailView() {
  const navigate = useNavigate()
  const user = useContextSelector(UserDetailContext, (s) => s.user)

  return (
    <CrumbedPage
      id="user-detail-page"
      title={user?.name || 'Usuário'}
      description=""
      paths={[
        {
          name: 'Início',
          location: '/',
        },
        {
          name: 'Usuários',
          location: '/users',
        },
        {
          name: user?.name || 'Usuário',
          location: `/users/${user?.id}`,
        },
      ]}
      actions={
        <Button
          text="Editar"
          onClick={() => navigate(`/users/${user?.id}/edit`)}
        />
      }
    >
      <Container>
        <About>
          <Info>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Email
            </Typography>
            <Shimmer height={20} width={300} isLoading={!user}>
              <Typography variant="$font-title-5" color="$color-text-primary">
                {user?.email}
              </Typography>
            </Shimmer>
          </Info>

          <Info>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Contato
            </Typography>
            <Shimmer height={20} width={100} isLoading={!user}>
              <Typography variant="$font-title-5" color="$color-text-primary">
                {user?.phone}
              </Typography>
            </Shimmer>
          </Info>

          <Info>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Perfil
            </Typography>
            <Shimmer height={20} width={100} isLoading={!user}>
              <Typography variant="$font-title-5" color="$color-action-link">
                {user?.admin ? 'Administrador' : 'Membro'}
              </Typography>
            </Shimmer>
          </Info>
          <Info>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Permissão
            </Typography>
            <Shimmer height={20} width={100} isLoading={!user}>
              <Typography variant="$font-title-5" color="$color-action-link">
                {NomosProductRoleName[user?.role as NomosProductCode] ||
                  'Nenhuma'}
              </Typography>
            </Shimmer>
          </Info>
        </About>

        <Info>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Painéis que possuí acesso
          </Typography>

          <Shimmer height={20} width={400} isLoading={!user} />

          <Monitors>
            {user?.monitors
              ?.filter((m) => !!m.name)
              .map((monitor) => (
                <Chip
                  key={monitor.monitorId}
                  variant="filled"
                  label={monitor.name}
                  color="primary"
                />
              ))}
          </Monitors>
        </Info>
      </Container>
    </CrumbedPage>
  )
}
