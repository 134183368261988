/* eslint-disable @typescript-eslint/no-explicit-any */
import { useModal } from '@contexts/Modal'
import { Button } from '@components/atoms'
import Typography from '@mui/material/Typography'
import { LaunchOutlined } from '@mui/icons-material'
import { capitalizeAllWords } from '@utils/functions/capitalize/capitalize'

import { Label, LabelContainer, ModalContainer } from './style'

type PropositionCellOpnionProps = {
  proposition: any
}

export function PropositionCellOpnion({
  proposition,
}: PropositionCellOpnionProps): JSX.Element {
  const { openModal } = useModal()

  const handleModal = () => {
    openModal(
      <ModalContainer>
        <Label>
          <Typography variant="$font-title-3" color="$color-text-primary">
            Parecer do relator
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Veja abaixo o parecer do relator atual para a proposição{' '}
            {proposition.title}
          </Typography>
        </Label>

        <LabelContainer>
          <Label>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Relator
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {capitalizeAllWords(proposition.rapporteur)}
            </Typography>
          </Label>
          <Label>
            <Typography variant="$font-body-base" color="$color-text-secundary">
              Partido
            </Typography>
            <Typography variant="$font-body-base" color="$color-text-primary">
              {proposition.rapporteurParty}
            </Typography>
          </Label>

          <Button
            href={proposition.rapporteurVoteUrl}
            target="_blank"
            rel="noreferrer"
            variant="text"
            text="Acessar documento"
            endIcon={<LaunchOutlined />}
          />
        </LabelContainer>
        <Typography variant="$font-body-base" color="$color-text-secundary">
          {proposition.rapporteurVote}
        </Typography>
      </ModalContainer>
    )
  }

  return (
    <Button
      size="tiny"
      sx={{ px: 1 }}
      variant="text"
      text="Ver parecer do relator"
      onClick={handleModal}
    />
  )
}
