import {
  NoteContentFileName,
  NoteContentFileWrapper,
  NoteContnetFileIconWrap,
} from './note_content_file.style'
import { useMemo, useState } from 'react'
import { File } from '@phosphor-icons/react'
import { NoteManagerDrawerContext } from '../context'
import { useContextSelector } from 'use-context-selector'
import { NoteEntity } from '@services/nomos_api/entities/note'
import { CircularProgress, Stack, Typography, Box } from '@mui/material'
import { formatBytes } from '@utils/functions/size/size'

export function NoteContentFile(note: NoteEntity): JSX.Element {
  const { file } = note
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<string | undefined>(undefined)
  const textcolor = useMemo(() => {
    return error ? '$color-semantic-error' : '$color-text-secundary'
  }, [error])

  const handleOpenNoteFile = useContextSelector(
    NoteManagerDrawerContext,
    (s) => s.handleOpenNoteFile
  )

  const handledOpenNoteFile = () => {
    setLoading(true)
    setStatus('Abrindo...')
    handleOpenNoteFile(note)
      .catch(() => {
        setStatus('Falha')
        setError(true)
      })
      .then(() => setStatus(undefined))
      .finally(() => setLoading(false))
  }

  return (
    <Stack alignItems="flex-start">
      <NoteContentFileWrapper
        spacing={1}
        direction="row"
        alignItems="center"
        onClick={handledOpenNoteFile}
      >
        <NoteContnetFileIconWrap
          error={String(error) as unknown as 'true' | 'false'}
        >
          <File weight="bold" size={16} />
        </NoteContnetFileIconWrap>
        <Stack direction="column">
          <NoteContentFileName variant="$font-body-3" color={textcolor}>
            {file?.slug || file?.name}
          </NoteContentFileName>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="$font-body-3" color={textcolor}>
              {formatBytes(file?.size || 0)}
            </Typography>
            <Typography variant="$font-body-3" color={textcolor}>
              -
            </Typography>
            <Typography variant="$font-body-3" color={textcolor}>
              {file?.mime}
            </Typography>
            {status && (
              <>
                <Typography variant="$font-body-3" color={textcolor}>
                  -
                </Typography>
                <Typography variant="$font-body-3" color={textcolor}>
                  {status}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
        {loading && (
          <Box width={16} height={16}>
            <CircularProgress size={16} />
          </Box>
        )}
      </NoteContentFileWrapper>
    </Stack>
  )
}
