/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState } from 'react'
import { theme } from '@theme/index'
import { useAuth } from '@contexts/Auth'
import { textToColor } from '@utils/functions/colors/colors'
import { NoteEntity } from '@services/nomos_api/entities/note'
import { generateInitials } from '@utils/functions/string/string'
import { formatPastDate } from '@utils/functions/formatter/formatter'
import { DotsThree, Globe, Lock, Pencil, Trash } from '@phosphor-icons/react'

import {
  Menu,
  Stack,
  Avatar,
  MenuItem,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material'

import {
  MentionStyled,
  MentionsInputStyle,
} from '@components/organisms/InputNote/InputNote.styles'
import { useContextSelector } from 'use-context-selector'
import { NoteManagerDrawerContext } from '../context'
import { useSnackbar } from '@contexts/Snackbar'
import { UpdateNoteEntity } from '@services/nomos_api/resources/notes/update'
import { NoteContentFile } from './note_content_file'
import { WrapperInputEditStyle } from './note_content_list_item.style'
import { Button } from '@components/atoms'

type Props = {
  data: NoteEntity
}

export function NoteContentListItem({ data }: Props): JSX.Element {
  const { user } = useAuth()

  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()
  const [anchorMenu, setAnchorMenu] = useState<Element | null | undefined>(null)
  const [deleting, setDeleting] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [noteValue, setNoteValue] = useState<string>(data.note)

  useEffect(() => {
    setNoteValue(data.note)
  }, [data.note])

  const mentions = useContextSelector(
    NoteManagerDrawerContext,
    (s) => s.mentions
  )

  const handleDeleteNote = useContextSelector(
    NoteManagerDrawerContext,
    (s) => s.handleDeleteNote
  )

  const handleUpdateNote = useContextSelector(
    NoteManagerDrawerContext,
    (s) => s.handleUpdateNote
  )

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorMenu(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorMenu(null)
  }

  const handleDeleteNoteById = () => {
    setDeleting(true)
    return handleDeleteNote(data.id)
      .then(() => {
        showSnackbarSuccess(
          'Anotação Removida',
          'Anotação removida com sucesso'
        )
      })
      .catch((error) => {
        showSnackbarError(
          'Erro ao remover anotação',
          error?.response?.data?.message ||
            'Não foi possível remover a anotação'
        )
      })
      .finally(() => {
        handleCloseMenu()
        setDeleting(false)
      })
  }

  const handleUpdateNoteById = (body: Partial<UpdateNoteEntity>) => {
    setUpdating(true)
    return handleUpdateNote(data.id, body)
      .then((updatedNote) => {
        showSnackbarSuccess(
          'Alteração realizada com sucesso',
          body.public !== undefined
            ? updatedNote.public
              ? 'Anotação pública! Todos os usuários da organização e visitantes da página da proposição poderão visualizá-la.'
              : 'Anotação privada! Agora apenas você pode visualizar essa anotação.'
            : 'O conteúdo da sua nota foi atualizado com sucesso.'
        )
      })
      .catch((error) => {
        showSnackbarError(
          'Erro ao atualizar a anotação',
          error?.response?.data?.message ||
            'Não foi possível atualizar a anotação'
        )
      })
      .finally(() => {
        handleCloseMenu()
        setUpdating(false)
      })
  }

  const handleTogglePublic = useCallback(() => {
    handleUpdateNoteById({ public: !data.public })
  }, [handleUpdateNoteById, data])

  const handleEnableEditNote = () => {
    setDisabled(false)
    handleCloseMenu()
  }

  const handleDisableEditNote = () => {
    setNoteValue(data.note)
    setDisabled(true)
    handleCloseMenu()
  }

  const handlePersistNoteValue = useCallback(() => {
    handleUpdateNoteById({ note: noteValue }).then(() => {
      setDisabled(true)
    })
  }, [noteValue, handleUpdateNoteById])

  return (
    <Stack
      direction="row"
      spacing={1}
      px={1}
      py={1}
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} sx={{ flex: 1 }}>
        <Avatar
          sx={{
            width: 32,
            height: 32,
            backgroundColor: textToColor(String(data.userId)),
          }}
        >
          <Typography variant="$font-body-2">
            {generateInitials(String(data.user?.name))}
          </Typography>
        </Avatar>
        <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
          {(!disabled || (data.note && data.note.length > 0)) && (
            <WrapperInputEditStyle
              spacing={1}
              direction="column"
              disabled={String(disabled) as 'true' | 'false'}
            >
              <MentionsInputStyle
                disabled={disabled}
                value={noteValue}
                allowSpaceInQuery
                allowSuggestionsAboveCursor
                className="mentions_input"
                onChange={(e) => setNoteValue(e.target.value)}
                placeholder="Escreva uma nota ou faça uma menção utilizando “@”"
              >
                <MentionStyled
                  trigger="@"
                  data={mentions}
                  displayTransform={(_, display) => `@${display}`}
                />
              </MentionsInputStyle>
              {!disabled && (
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  className="edit-note-actions"
                >
                  <Button
                    sx={{ px: 1 }}
                    size="tiny"
                    text="Cancelar"
                    variant="outlined"
                    disabled={updating}
                    onClick={handleDisableEditNote}
                  />
                  <Button
                    sx={{ px: 1 }}
                    size="tiny"
                    text="Salvar"
                    variant="contained"
                    loading={updating}
                    onClick={handlePersistNoteValue}
                  />
                </Stack>
              )}
            </WrapperInputEditStyle>
          )}
          {data.file && <NoteContentFile {...data} />}
          <Stack direction="row" spacing={1} alignItems="center">
            {data.public ? (
              <Globe size={12} color={theme.palette['$color-text-secundary']} />
            ) : (
              <Lock size={12} color={theme.palette['$color-text-secundary']} />
            )}
            <Typography variant="$font-body-2" color="$colot-text-primary">
              Por {data.user?.name}
            </Typography>
            <Typography variant="$font-body-2" color="$color-text-secundary">
              - {formatPastDate(new Date(data?.createdAt))}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {data.userId === user?.id && disabled === true && (
        <>
          <IconButton
            sx={{ width: 28, height: 28, p: 0 }}
            onClick={handleOpenMenu}
          >
            <DotsThree />
          </IconButton>
          <Menu
            id="menu-options-note"
            anchorEl={anchorMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorMenu)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => handleEnableEditNote()}>
              <Stack spacing={1} direction="row" alignItems="center">
                <Pencil />
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  Editar
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem
              onClick={() => handleDeleteNoteById()}
              disabled={deleting}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                {deleting ? (
                  <CircularProgress size={15} />
                ) : (
                  <Trash color={theme.palette['$color-semantic-error']} />
                )}
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  Excluir
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem onClick={() => handleTogglePublic()} disabled={updating}>
              <Stack direction="row" spacing={1} alignItems="center">
                {updating ? (
                  <CircularProgress size={15} />
                ) : data.public ? (
                  <Lock />
                ) : (
                  <Globe />
                )}
                <Typography
                  variant="$font-body-base"
                  color="$color-text-secundary"
                >
                  {data.public ? 'Tornar Privado' : 'Tornar Público'}
                </Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </>
      )}
    </Stack>
  )
}
