import styled from 'styled-components'
import { styled as StyledMui, FormControlLabel } from '@mui/material'
import { theme } from '@theme/index'

export const InputCheckboxLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: ${theme.palette['$color-text-secundary']};
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    text-overflow: ellipsis !important;
    word-wrap: break-word !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
`

export const CheckboxIcon = StyledMui('span')(({ theme }) => ({
  borderRadius: 4,
  marginLeft: 2,
  width: 16,
  height: 16,
  border: `1px solid ${theme.palette.muted.main}`,
  backgroundColor: 'transparent',
  '.Mui-focusVisible &': {
    outline: `1px auto ${theme.palette.primary.main}`,
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.common.white,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}))

export const CheckboxActiveIcon = StyledMui(CheckboxIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.primary.main,
  },
}))
