/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled'
import colors from '@theme/colors'

export const InputPinField = styled.input<{
  done: 'true' | 'false'
}>`
  flex: 1;
  height: 50px;
  width: 100%;
  min-height: 50px;
  min-width: auto;
  max-height: 50px;
  max-width: 50px;
  outline: none;
  border-width: 1px;
  border-radius: 6px;
  border-style: solid;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  border-color: ${({ done }) => {
    return done === 'true'
      ? colors['$color-border-tertiary']
      : colors['$color-border-secundary']
  }};
  font-size: 26px;
  text-align: center;
  color: ${colors['$color-text-primary']};
  &:focus {
    outline: 1px solid ${colors['$color-action-link']};
    border-color: ${colors['$color-action-link']} !important;
  }
`
