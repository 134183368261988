import { useModal } from '@contexts/Modal'
import { useDrawer } from '@contexts/Drawer'
import { Trash } from '@phosphor-icons/react'
import { EditMonitorContext } from './context'
import { useContext, useCallback } from 'react'
import { ContainerScroll } from '@components/atoms'
import RemoveMonitorModal from '../RemoveMonitorModal'
import { CardRadio } from '@components/organisms/Cards'
import { IconButton, Stack, Typography } from '@mui/material'
import { Button as Button, Input as Input } from '@components/atoms'
import {
  Container,
  Content,
  Header,
  Title,
  IconClose,
  ContentAction,
} from './styles'

export function EditMonitorDrawerView(props) {
  const { formikPainel, isLoading } = useContext(EditMonitorContext)
  const { onClose, monitor, onDeleteMonitor } = props

  const { closeDrawer } = useDrawer()
  const { openModal } = useModal()

  const handleClose = useCallback(() => {
    if (onClose) onClose()
    closeDrawer()
  }, [onClose, closeDrawer])

  const isSwitchModified = formikPainel.values.public !== monitor?.public
  const isDisableSaveButton = !(formikPainel.dirty && (formikPainel.isValid || isSwitchModified)) || isLoading

  return (
    <Container>
      <Content>
        <Header>
          <Title variant="$font-title-3">Opções do Painel</Title>
          <IconButton onClick={() => handleClose()}>
            <IconClose />
          </IconButton>
        </Header>
        <ContainerScroll pt={15} pr={5} auto="true" style={{ width: "100%" }}>
          <Stack direction="column" spacing={3} sx={{ maxWidth: "380px"}}>
            <Input
              label="Nome do Painel"
              name="name"
              error={
                formikPainel.touched.name &&
                Boolean(formikPainel.errors.name)
              }
              helpertext={
                (formikPainel.touched.name && formikPainel.errors.name) ||
                'Renomeie o painel para editá-lo'
              }
              disabled={isLoading}
              defaultValue={monitor?.name}
              onBlur={formikPainel.handleBlur}
              value={formikPainel.values.name}
              onChange={formikPainel.handleChange}
            />
            <Stack direction="column">
              <Typography variant="$font-body-1" color="$color-neutral-gray-100">
                Tipo de Permissão
              </Typography>
              <Typography variant="$font-body-base" color="$color-text-secundary">
                Defina o tipo de permissão do painel
              </Typography>
            </Stack>
            <CardRadio
              name="public"
              disabled={isLoading}
              value={formikPainel.values.public}
              onChange={(_e, value) => {
                formikPainel.setFieldValue('public',value)
              }}
              options={[
                {
                  title: 'Público',
                  value: "true",
                  description: (
                    <Typography variant="$font-body-3" color="$color-text-secundary">
                      Todos os membros visualizam e editam o painel
                    </Typography>
                  ),
                },
                {
                  title: 'Privado',
                  value: "false",
                  description: (
                    <Typography variant="$font-body-3" color="$color-text-secundary">
                      Você decide quem terá acesso ao painel
                    </Typography>
                  ),
                },
              ]}
            />
          </Stack>
        </ContainerScroll>
        <ContentAction>        
          <Button
            id="delete-panel-btn"
            variant="text"
            color="$color-extended-red1"
            size="large"
            text="Excluir Painel"
            disabled={isLoading}
            startIcon={<Trash/>}
            sx={{ flex: 1}}
            onClick={() => {
              closeDrawer()
              openModal(<RemoveMonitorModal onConfirm={onDeleteMonitor} />)
            }}
          />
          <Button
            text="Salvar"
            size="large"
            sx={{ flex: 1}}
            loading={isLoading}
            disabled={isDisableSaveButton}
            onClick={formikPainel.handleSubmit}
          />
        </ContentAction>
      </Content>
    </Container>
  )
}
