import { Typography } from '@mui/material'

import { Sum } from './styles'

export type CardSumProps = {
  count: number
  label: string
}

export default function CardSum({ count, label }: CardSumProps) {
  return (
    <Sum>
      <Typography variant="$font-title-1" color="$color-action-link">
        {count}
      </Typography>

      <Typography variant="$font-body-base" color="$color-text-secundary">
        {label}
      </Typography>
    </Sum>
  )
}
