/* eslint-disable no-inline-styles/no-inline-styles */
import { theme } from '@theme/index'
import { Container, IFrameContainer } from './styles'

export default function InsightsBrazil() {
  const filter = {}

  return (
    <Container>
      <IFrameContainer>
        <iframe
          width="100%"
          height="100%"
          title="Nomos Insights"
          style={{
            margin: '0 6%',
            borderTop: '0px',
            paddingTop: '10px',
            background: 'white',
            borderBottom: '0px',
            borderLeft: `1px solid ${theme.palette['$color-border-secundary']}`,
            borderRight: `1px solid ${theme.palette['$color-border-secundary']}`,
          }}
          src={`https://charts.mongodb.com/charts-nomos-oxgic/embed/dashboards?id=0ec41506-bf03-45f3-b615-bf20433a8f4a&theme=light&autoRefresh=true&maxDataAge=86400&showTitleAndDesc=false&scalingWidth=scale&scalingHeight=fixed&attribution=false&filter=${JSON.stringify(
            filter
          )}`}
        />
      </IFrameContainer>
    </Container>
  )
}
