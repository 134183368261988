import { useModal } from '@contexts/Modal'
import { Close } from '@mui/icons-material'
import { TextLink } from '@components/atoms'
import { Container, Content } from './styles'
import { IconButton, ListItem, Stack, Typography } from '@mui/material'

type GuestsType = {
  name: string
  acronymParty: string
  stakeholderId: string
}

type EventThemeModalProps = {
  numberGuests: number
  listGuests: GuestsType[]
}

export default function EventGuestsModal({
  numberGuests,
  listGuests,
}: EventThemeModalProps) {
  const { closeModal } = useModal()

  return (
    <Container>
      <Stack
        spacing={1}
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="$font-title-3" color="$color-text-primary">
            Convidados
          </Typography>
          <Typography variant="$font-body-base" color="$color-text-secundary">
            Veja os {numberGuests} convidados deste evento
          </Typography>
        </Stack>
        <IconButton onClick={closeModal}>
          <Close />
        </IconButton>
      </Stack>
      <Content>
        {listGuests?.map((guests) => (
          <ListItem>
            <TextLink
              text={`${guests?.name} - ${guests?.acronymParty}`}
              href={`/stakeholders/${guests?.stakeholderId}`}
            />
          </ListItem>
        ))}
      </Content>
    </Container>
  )
}
