import { useState } from 'react'
import { ChipButton, Label, ButtonIcon } from './ChipSelect.styles'
import { Box, Menu, MenuItem, ButtonProps } from '@mui/material'
import { Input } from '../index'

export type ChipSelectProps = {
  id: string
  selectedFilters: { key: string; name: string }[]
  otherFilters: { key: string; name: string }[]
  actionAdd: (key: string) => void
  actionRemove: (key: string) => void
} & Pick<ButtonProps, 'variant' | 'size'>

function ChipSelect({
  id,
  size,
  variant,
  selectedFilters,
  otherFilters,
  actionAdd,
  actionRemove,
  ...rest
}: ChipSelectProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleClose = () => setAnchorEl(null)

  return (
    <Box>
      <ChipButton
        id={id}
        size={size || 'medium'}
        variant={variant || 'contained'}
        endIcon={<ButtonIcon />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        {...rest}
      />
      <Menu
        sx={{ marginTop: 1 }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {selectedFilters.length > 0 && (
          <Label variant="$font-body-base" color="$color-text-secundary">
            Categorias selecionadas
          </Label>
        )}

        {selectedFilters.map((item) => (
          <MenuItem key={item.key} onClick={() => actionRemove(item.key)}>
            <Input type="checkbox" label={item.name} checked />
          </MenuItem>
        ))}

        {otherFilters.length > 0 && (
          <Label variant="$font-body-base" color="$color-text-secundary">
            Outros
          </Label>
        )}

        {otherFilters?.map((item) => (
          <MenuItem key={item.key} onClick={() => actionAdd(item.key)}>
            <Input type="checkbox" label={item.name} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default ChipSelect
