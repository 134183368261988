import colors from '@theme/colors'
import { Chip, Typography } from '@mui/material'
import emotionStyled from '@emotion/styled'
import { ClearRounded } from '@mui/icons-material'
import { styled, chipClasses } from '@mui/material'

export const Wrapper = emotionStyled.div``

export const ChipIconDelete = styled(ClearRounded)`
  &.MuiSvgIcon-root {
    font-size: 14px !important;
    color: ${colors['$color-text-secundary']} !important;
    &:hover {
      color: ${colors['$color-text-primary']} !important;
    }
  }
`

export const AdaptiveChip = styled(Chip)<{ disableStyle: 'true' | 'false' }>`
  height: 28px;
  padding: 0px 8px;
  max-width: 300px;
  border-width: 0px;
  margin-right: 8px;
  margin-bottom: 6px;
  border-radius: 1px;
  color: ${colors['$color-action-border']};
  background-color: ${(props) =>
    props.disableStyle === 'true'
      ? 'white'
      : colors['$color-background-tertiary']};
  & .${chipClasses.label} {
    padding-left: 0px;
    padding-right: 0px;
  }
  & .${chipClasses.deleteIcon} {
    margin-right: 0px !important;
    margin-left: 4px;
  }
`

export const ChipConnector = styled((props) => (
  <Typography
    {...props}
    variant="$font-body-base"
    color="$color-text-secundary"
  />
))`
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold !important;
`
