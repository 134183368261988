import { CardWrapper, CardContent, CardBaseline, CardActions } from './styles'

type Props = {
  cy?: string
  content: JSX.Element | JSX.Element[]
  actions: JSX.Element | JSX.Element[]
}

export function CardTemplate(props: Props) {
  const { cy, content, actions } = props
  return (
    <CardWrapper data-cy={cy}>
      <CardBaseline>
        <CardContent>{content}</CardContent>
        <CardActions>{actions}</CardActions>
      </CardBaseline>
    </CardWrapper>
  )
}
