/* eslint-disable @typescript-eslint/no-explicit-any */
import { BucketsEnum } from '@utils/buckets/type'

export enum MonitoringTab {
  'monitor' = 'monitor',
  'unmonitor' = 'unmonitor',
}

export type MonitoringTabKeys = keyof typeof MonitoringTab

export type MonitoringDrawerProps = {
  id: string
  origin: BucketsEnum
  house?: string
  isSaved?: boolean
  onFinish?: (data?: any) => void
  onRemove?: (removedIds: string[]) => void
}

export type MonitoringControllerProps = MonitoringDrawerProps & {
  children: React.ReactChild
}

export type MonitoringContextProps = {
  id: string
  origin: BucketsEnum
  selectedTab: MonitoringTabKeys
  onFinish: ((data?: any) => void) | undefined
  onRemove: ((removedIds: string[]) => void) | undefined
  setSelectedTab: React.Dispatch<React.SetStateAction<MonitoringTabKeys>>
  house?: string
  isSaved?: boolean
}
