import { useParams } from 'react-router-dom'
import { SecundaryPage } from '@components/templates'
import { SavedSearchesList } from '@containers/SavedSearchesList'

export function SavedSearchPanel() {
  const { id } = useParams()

  return (
    <SecundaryPage
      id="saved-search-page"
      title="Pesquisas salvas"
      description="Visualize e edite todas as pequisas salvas e tenha acesso a pesquisas recomendadas pela inteligência Nomos."
    >
      <SavedSearchesList monitorId={id} />
    </SecundaryPage>
  )
}
